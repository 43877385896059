/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useMemo, useState } from 'react';
import GroupButtons from '../../components/GroupButtons';
import { useMedplum } from '@medplum/react';
import { MedicalHistory } from './MedicalHistory';

const PatientMedicalHistory = () => {
  const medplum = useMedplum();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [resourceData, setResourceData] = useState<any[]>([]);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState();
  const buttonName: any = [
    { id: 1, name: 'Assessments' },
    { id: 2, name: 'Conditions' },
    { id: 3, name: 'Clinical Notes' },
    { id: 4, name: 'Procedures' },
    { id: 5, name: 'Allergies' },
    { id: 6, name: 'Medications' },
  ];

  const buttonHandleClick = (data: { id: number; name: string }) => {
    setActiveTab(data.id);
  };

  const getResourceType = () => {
    const resourceType: Record<number, string> = {
      1: 'QuestionnaireResponse',
      2: 'Condition',
      3: 'DocumentReference',
      4: 'Procedure',
      5: 'AllergyIntolerance',
      6: 'MedicationRequest',
    };

    return resourceType[activeTab];
  };

  useEffect(() => {
    setResourceData([]);
    const resourceType = getResourceType();
    if (resourceType === 'QuestionnaireResponse') {
      // First, fetch QuestionnaireResponses for the patient
      setIsloading(true);
      medplum
        .searchResources('QuestionnaireResponse', {
          patient: `Patient/${medplum.getProfile()?.id}`,
        })
        .then(async (responses: any[]) => {
          // Now, fetch the associated Questionnaires
          const questionnairePromises = responses.map((response) => {
            // console.log(response.questionnaire);

            if (response?.questionnaire) {
              return medplum
                .readReference({ reference: response.questionnaire, type: 'Questionnaire' })
                .catch((error: any) => {
                  // Handle the error
                  console.error(error);
                  return null;
                });
            } else {
              return Promise.resolve(null);
            }
          });

          // console.log(questionnairePromises);

          const questionnaires = await Promise.all(questionnairePromises);

          // // Combine QuestionnaireResponses with their Questionnaires
          const combinedData = responses.map((response, index) => ({
            response,
            questionnaire: questionnaires[index],
          }));

          setResourceData(combinedData);
          setIsloading(false);
        })
        .catch((err: any) => {
          console.error(err);
          setResourceData([]);
          setIsloading(false);
        });
    } else {
      getAPIResource(0, resourceType);
    }
  }, [activeTab]);

  const getAPIResource = (_offset: number, resourceType?: string) => {
    setIsloading(true);
    medplum
      .search(
        resourceType as
          | 'QuestionnaireResponse'
          | 'Condition'
          | 'DocumentReference'
          | 'Procedure'
          | 'AllergyIntolerance'
          | 'MedicationRequest',

        {
          _count: 10,
          _offset: _offset, // Add your offset value here
          _sort: '-_lastUpdated',
          _total: 'accurate',
          patient: `Patient/${medplum.getProfile()?.id}`,
        }
      )
      .then((res: any) => {
        setResourceData(res?.entry);
        setResponseData(res);
        setIsloading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setResourceData([]);
      });
  };

  const getOffsetValue = (updatedOffset: number) => {
    const resourceType = getResourceType();
    getAPIResource(updatedOffset, resourceType);
  };

  const memoizedPatientInfo = useMemo(
    () => (
      <MedicalHistory
        activeTab={activeTab}
        resourceData={resourceData}
        isLoading={isLoading}
        offsetValue={(offset) => getOffsetValue(offset)}
        responseData={responseData}
      />
    ),
    [activeTab, isLoading, resourceData]
  );

  return (
    <>
      <div className="tw-border tw-border-[#EAEyCF0] tw-rounded-[12px]">
        <div className="tw-px-4 tw-pt-4">
          <h5 className="tw-text-[18px] xs:tw-text-[18px] tw-font-semibold tw-tracking-tight tw-text-[#101828]">
            Medical History
          </h5>
        </div>
        <hr className="h-px tw-my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        <div className="tw-flex tw-justify-between tw-px-4 tw-py-4">
          <GroupButtons value={buttonName} buttonHandleClick={(data) => buttonHandleClick(data)} />
        </div>
        <div>{memoizedPatientInfo}</div>
      </div>
    </>
  );
};

export default PatientMedicalHistory;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMedplum } from '@medplum/react';
import { Modal, Select, Grid, Text, Button, Divider, Checkbox, Notification, Loader } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { getPrimaryConditions, createPatientCondition, updatePatientCondition } from '../utils/CustomAPI';
import { useForm } from '@mantine/form';
import { useBrandSettings } from '../BrandContext';
import { Condition, Identifier } from '@medplum/fhirtypes';
import { showNotification } from '@mantine/notifications';
import { ConditionPayload } from '../interfaces/DataPoint';
import { useAppContext } from '../AppProvider';
import { useNavigate } from 'react-router-dom';

interface AddConditionProps {
  opened: boolean;
  isAddCondition: boolean;
  close: () => void;
  selectedData?: Condition;
}

interface DataValue {
  value: string;
  label: string;
}

const AddCondition = (props: AddConditionProps) => {
  const StageValue = [
    { value: '13104003', label: 'Clinical stage I' },
    { value: '60333009', label: 'Clinical stage II' },
    { value: '50283003', label: 'Clinical stage III' },
    { value: '2640006', label: 'Clinical stage IV' },
  ];
  const severityData = [
    { value: '255604002', label: 'Mild' },
    { value: '24484000', label: 'Severe' },
    { value: '6736007', label: 'Moderate' },
  ];
  const form = useForm({
    initialValues: {
      condition: '',
      stage: '',
      severity: 'Mild',
      comments: '',
    },
    validate: {
      condition: (value: string | undefined) => (value ? null : 'Please fill the required fields'),
      stage: (value: string | undefined) => (value ? null : 'Please fill the required fields'),
      severity: (value: string | undefined) => (value ? null : 'Please fill the required fields'),
    },
  });
  const medplum = useMedplum();
  const brandDetails = useBrandSettings();
  const navigate = useNavigate();
  const { setIsCheckPageRefresh } = useAppContext();
  const [severity, setSeverity] = React.useState<DataValue[]>([{ value: '255604002', label: 'Mild' }]);
  const [primaryConditions, setPrimaryConditions] = useState<DataValue[]>([]);
  const [isPrimaryCondition, setIsPrimaryCondition] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isCheckNoCondition, setIsCheckNoCondition] = useState(false);

  const fetchConditions = async (searchText: string) => {
    if (searchText.length >= 3) {
      const response = await getPrimaryConditions(searchText);
      if (response) {
        setPrimaryConditions(
          response.primaryConditionsDropdown.map((item: { code: string; display: string }) => ({
            value: item.code,
            label: item.display,
          }))
        );
        if (response.primaryConditionsDropdown.length === 0) {
          setIsCheckNoCondition(true);
        }
      }
    } else {
      setPrimaryConditions([]);
      setIsCheckNoCondition(false);
    }
  };

  useEffect(() => {
    if (!props.isAddCondition && props.selectedData) {
      const selectedCondition = {
        value: props?.selectedData?.code?.coding?.[0]?.code ?? '',
        label: props?.selectedData?.code?.coding?.[0]?.display ?? '',
      };

      // Add missing condition to the primaryConditions list if not present
      if (!primaryConditions.some((item) => item.value === selectedCondition.value)) {
        setPrimaryConditions((prev) => [...prev, selectedCondition]);
      }
      form.setFieldValue('condition', props?.selectedData?.code?.coding?.[0]?.code ?? '');
      form.setFieldValue('conditionLabel', props?.selectedData?.code?.coding?.[0]?.display);
      form.setFieldValue('stage', props?.selectedData?.stage?.[0]?.summary?.coding?.[0]?.code ?? '');
      form.setFieldValue('stageLable', props?.selectedData?.stage?.[0]?.summary?.coding?.[0]?.display);
      setSeverity([
        {
          value: props?.selectedData?.severity?.coding?.[0]?.code ?? '',
          label: props?.selectedData?.severity?.coding?.[0]?.display ?? '',
        },
      ]);
      setIsPrimaryCondition(
        props?.selectedData?.identifier?.find((data: Identifier) => data.value === 'primary-condition') !== undefined
      );
    }
  }, [props.selectedData]);

  const handleSubmit = async (values: Record<string, unknown>) => {
    setLoading(true);
    const patientId = medplum.getProfile()?.id ?? '';
    if (patientId) {
      if (!values.condition || !values.stage || !severity) {
        setErrorMessage('Please fill the required fields');
        return;
      }

      const conditionPayload = {
        patientId: medplum.getProfile()?.id ?? '',
        primaryCondition: { code: values.condition as string, display: values?.conditionLabel as string },
        stage: { code: values.stage as string, display: values.stageLable as string },
        conditionSeverity: { code: severity[0].value, display: severity[0].label },
        projectName: brandDetails?.brandName ?? '',
        isPrimaryCondition: isPrimaryCondition,
      };
      if (!props.isAddCondition && props?.selectedData?.id) {
        updateCondition(conditionPayload);
      } else {
        createPatientCondition(conditionPayload)
          .then(() => {
            setLoading(false);
            setIsCheckPageRefresh(true);
            showNotification({ message: 'Condition added successfully', color: 'blue' });
            navigate('/dashboard');
            props.close();
          })
          .catch((error) => {
            setLoading(false);
            console.log('Error while adding condition', error);
            showNotification({ message: 'Error while adding condition', color: 'red' });
          });
      }
    }
  };

  // Update condition
  const updateCondition = (conditionPayload: ConditionPayload) => {
    conditionPayload.conditionId = props?.selectedData?.id ?? '';
    updatePatientCondition(medplum, conditionPayload)
      .then(() => {
        setIsCheckPageRefresh(true);
        showNotification({ message: 'Condition updated successfully', color: 'blue' });
        navigate('/dashboard');
        props.close();
      })
      .catch(() => {
        showNotification({ message: 'Error while updating condition', color: 'red' });
      });
  };

  return (
    <Modal
      size="46%"
      opened={props.opened}
      onClose={props.close}
      title={<span style={{ fontWeight: 500 }}>Add New Condition</span>}
    >
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid align="center" style={{ margin: '10px 0 0' }}>
          <Grid.Col span={3.5}>
            <Text fw={500} style={{ fontSize: '14px', color: '#000' }}>
              Condition <span style={{ color: 'red' }}>*</span>
            </Text>
          </Grid.Col>
          <Grid.Col span={8.5}>
            <Select
              placeholder="Start typing a condition"
              searchable
              nothingFoundMessage={isCheckNoCondition ? 'No conditions found' : ''}
              required
              name="condition"
              {...form.getInputProps('condition')}
              onChange={(value) => {
                const selectedCondition = primaryConditions?.find((condition) => condition.value === value);
                form.setFieldValue('condition', value ?? '');
                form.setFieldValue('conditionLabel', selectedCondition?.label);
              }}
              onSearchChange={(value) => {
                if (value === form.values.condition) {
                  return;
                }
                if (value.trim() === '') {
                  form.setFieldValue('condition', '');
                  form.setFieldValue('conditionLabel', '');
                }
                fetchConditions(value);
              }}
              data={primaryConditions}
              styles={{
                option: () => ({
                  '&[data-selected] svg': {
                    display: 'none',
                  },
                }),
              }}
            />
          </Grid.Col>
        </Grid>
        <Grid align="center" style={{ margin: '10px 0 15px' }}>
          <Grid.Col span={3.5}>
            <Text fw={500} style={{ fontSize: '14px', color: '#000' }}>
              Stage <span style={{ color: 'red' }}>*</span>
            </Text>
          </Grid.Col>
          <Grid.Col span={8.5}>
            <Select
              placeholder="Select a stage"
              name="stage"
              {...form.getInputProps('stage')}
              onChange={(value) => {
                const selectedStage = StageValue?.find((stage) => stage.value === value);
                form.setFieldValue('stage', value ?? '');
                form.setFieldValue('stageLable', selectedStage?.label);
              }}
              data={StageValue}
            />
          </Grid.Col>
        </Grid>
        <Grid align="center" style={{ margin: '10px 0 15px' }}>
          <Grid.Col span={3.5}>
            <Text fw={500} style={{ fontSize: '14px', color: '#000' }}>
              Severity <span style={{ color: 'red' }}>*</span>
            </Text>
          </Grid.Col>
          <Grid.Col span={8.5}>
            <Grid ml={6}>
              {severityData?.map((item, index) => (
                <Button
                  variant="light"
                  color="gray"
                  radius={8}
                  onClick={() => setSeverity([{ value: item.value, label: item.label }])}
                  key={index}
                  style={{
                    width: 'auto',
                    backgroundColor: severity?.[0]?.label === item?.label ? '#DCDCDC' : '#fff',
                    color: severity?.[0]?.label === item?.label ? '#000' : '#344054',
                    border: severity?.[0]?.label === item?.label ? `1px solid #A9A9A9` : '1px solid #D0D5DD',
                    marginRight: '8px',
                  }}
                >
                  {item?.label}
                </Button>
              ))}
            </Grid>
          </Grid.Col>
        </Grid>
        <Grid align="center" style={{ margin: '10px 0 20px' }}>
          <Grid.Col span={3.5}></Grid.Col>
          <Grid.Col span={8.5}>
            <Checkbox
              label="Make this as a primary condition"
              checked={isPrimaryCondition}
              onChange={(event) => setIsPrimaryCondition(event.currentTarget.checked)}
              color={brandDetails?.buttonColor || '#9552E8'}
            />
          </Grid.Col>
        </Grid>
        {errorMessage && (
          <Notification color="red" onClose={() => setErrorMessage('')}>
            {errorMessage}
          </Notification>
        )}
        <Divider />
        <Grid gutter="xs" justify="flex-end" style={{ margin: '20px 0 0' }}>
          <Grid.Col span={2.5}>
            <Button
              radius="md"
              size="md"
              style={{
                background: '#DCDCDC',
                color: '#000',
                fontWeight: 600,
                marginRight: '10px',
              }}
              onClick={props.close}
            >
              Cancel
            </Button>
          </Grid.Col>
          <Grid.Col span={3}>
            <Button
              radius="md"
              size="md"
              style={{
                color: brandDetails?.secondaryColor ? brandDetails.secondaryColor : '#667085',
                border: brandDetails?.secondaryColor ? brandDetails.secondaryColor : '#667085',
                backgroundColor: brandDetails?.buttonColor ? brandDetails.buttonColor : '#fff',
                fontWeight: 600,
              }}
              type="submit"
            >
              {loading && <Loader size="sm" color="white" mr={10} />} Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};

export default AddCondition;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AreaChart, Area, Tooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Skeleton, Modal } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { useEffect, useState } from 'react';
import { getBloodPressure } from '../../utils/CustomAPI';
import { IconArrowDown, IconArrowUp, IconExternalLink } from '@tabler/icons-react';
import { formatDate } from '../../utils/util';

// Define the DataPoint interface to include systolic, diastolic, and date
export interface DataPoint {
  systolic: number;
  diastolic: number;
  date: string;
}

const BloodPressure = () => {
  const medplum = useMedplum();
  const [bloodPressureData, setBloodPressureData] = useState<DataPoint[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bloodPressureAvg, setBloodPressureAvg] = useState<number>(0);
  const [percentageChange, setPercentageChange] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to remove duplicate entries
  const removeDuplicates = (data: DataPoint[]) => {
    const uniqueData = data.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.date === value.date && t.systolic === value.systolic && t.diastolic === value.diastolic
      ))
    );
    return uniqueData;
  };

  useEffect(() => {
    //get the previous month date
    setIsLoading(true);
    const previousMonth = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString();
    getBloodPressure(medplum, medplum.getProfile()?.id || '', previousMonth)
      .then((res) => {
        setIsLoading(false);
        const data = res?.data.entry
          .map((entry: any) => {
            const systolic = entry?.resource?.component?.find((comp: any) => comp.code.coding[0]?.code === '8480-6')
              ?.valueQuantity?.value;
            const diastolic = entry?.resource?.component?.find((comp: any) => comp.code.coding[0]?.code === '8462-4')
              ?.valueQuantity?.value;
            return {
              systolic: systolic || 0,
              diastolic: diastolic || 0,
              date: entry.resource.effectiveDateTime,
            };
          })
          .sort((a: DataPoint, b: DataPoint) => new Date(a.date).getTime() - new Date(b.date).getTime()) // Sort by effectiveDateTime in ascending order
          .map((entry: DataPoint) => ({
            systolic: entry.systolic,
            diastolic: entry.diastolic,
            date: formatDate(entry.date),
          }));
        const uniqueData = removeDuplicates(data); // Remove duplicate entries
        setBloodPressureData(uniqueData);
        // Calculate average blood pressure and percentage change
        const bloodPressure = res?.data.entry
          .slice(1)
          .map((entry: any) => entry.resource?.component?.[0].valueQuantity?.value);
        const sum = bloodPressure.reduce((a: any, b: any) => a + b, 0);
        const avg: any = (sum / bloodPressure.length || 0).toFixed(2);
        setBloodPressureAvg(Number(avg));

        const percentageChange = (
          (Math.abs(avg - res?.data.entry?.[0]?.resource?.component?.[0].valueQuantity?.value) / avg) *
          100
        ).toFixed(2);
        setPercentageChange(percentageChange === 'NaN' ? avg : percentageChange);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="tw-mt-6 xs:tw-mt-2 tw-px-5 tw-py-4 tw-block tw-w-[50%] tw-h-fit tw-bg-white tw-border border-[#F2F2F2] tw-rounded-[12px] featured-card">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
        <h5 className="tw-text-[16px] tw-font-semibold tw-tracking-tight tw-text-[#101828] tw-pb-0">Blood Pressure</h5>
        {bloodPressureData.length > 0 && (
          <IconExternalLink
            size={20}
            className="tw-cursor-pointer tw-text-[#667085]"
            title="Click to view your blood pressure history."
            onClick={() => setIsModalOpen(true)}
          />
        )}
      </div>
      {!isLoading ? (
        <div className="tw-flex tw-justify-between tw-mt-5">
          <div className="tw-grid tw-w-[50%]">
            <span className="tw-font-semibold tw-text-[#101828] tw-text-[22px] tw-mb-3">
              {bloodPressureData?.length > 0 && bloodPressureData[bloodPressureData.length - 1]?.systolic && bloodPressureData[bloodPressureData.length - 1]?.diastolic
                ? `${bloodPressureData[bloodPressureData.length - 1]?.systolic}/${bloodPressureData[bloodPressureData.length - 1]?.diastolic} mmHg`
                : '0/0 mmHg'}
            </span>
            <span
              className="tw-text-[#067647] tw-flex tw-items-center tw-font-medium"
              style={{
                color:
                  bloodPressureAvg > bloodPressureData?.[0]?.systolic || bloodPressureAvg === 0.0
                    ? '#17B26A'
                    : '#FF5C58',
              }}
            >
              {bloodPressureAvg > bloodPressureData?.[0]?.systolic || bloodPressureAvg === 0.0 ? (
                <IconArrowUp size={15} color="#17B26A" />
              ) : (
                <IconArrowDown size={15} color="#FF5C58" />
              )}
              {percentageChange}%<span className="tw-ml-1 tw-text-[#475467] tw-font-medium">vs last month</span>
            </span>
          </div>
          <div className="tw-w-[50%]">
            <ResponsiveContainer>
              <AreaChart data={bloodPressureData} barSize={5}>
                <defs>
                  <linearGradient id="colorUv3" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={
                        bloodPressureAvg > bloodPressureData?.[0]?.systolic || bloodPressureAvg === 0.0
                          ? '#17B26A'
                          : '#FF5C58'
                      }
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor={bloodPressureAvg > bloodPressureData?.[0]?.systolic ? '#17B26A' : '#FF5C58'}
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>
                <XAxis dataKey="date" tickLine={false} display="none" />
                <Tooltip cursor={false} />
                <Area
                  dataKey="systolic"
                  fill="url(#colorUv3)"
                  stroke={
                    bloodPressureAvg > bloodPressureData?.[0]?.systolic || bloodPressureAvg === 0.0
                      ? '#17B26A'
                      : '#FF5C58'
                  }
                  strokeWidth={2}
                  name="Systolic Blood Pressure"
                />
                <Area
                  dataKey="diastolic"
                  fill="url(#colorUv3)"
                  stroke="#FF5C58"
                  strokeWidth={2}
                  name="Diastolic Blood Pressure"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className="tw-grid tw-text-center tw-mt-[20px] tw-block tw-m-auto">
          <Skeleton height={8} mb="xs" />
          <Skeleton height={8} mb="xs" />
          <Skeleton height={8} mb="xs" />
        </div>
      )}
      {/* Modal for Enlarged Chart */}
      <Modal opened={isModalOpen} onClose={() => setIsModalOpen(false)} title={<span style={{ fontSize: '16px', fontWeight: 600 }}>Blood Pressure</span>} size="lg" centered>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={bloodPressureData} margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
            <XAxis
              dataKey="date"
              tickFormatter={(tick) => {
                const date = new Date(tick.split('/').reverse().join('-'));
                return `${date.getDate()}\n${date.toLocaleDateString('en-US', { month: 'short' })}`;
              }}
              interval={0}
              angle={-25}
              dy={10}
              tick={{ fontSize: 10, textAnchor: "middle" }}
            />
            <YAxis tick={{ fontSize: 10 }} />
            <Tooltip cursor={false} />
            <Area dataKey="systolic" stroke="#17B26A" fill="url(#colorUv3)" strokeWidth={2} />
            <Area dataKey="diastolic" stroke="#FF5C58" fill="url(#colorUv3)" strokeWidth={2} />
          </AreaChart>
        </ResponsiveContainer>
      </Modal>
    </div>
  );
};

export default BloodPressure;

import { useEffect, useMemo, useState } from 'react';
import { LiveKitRoom, RoomAudioRenderer, VideoConference } from '@livekit/components-react';
import { ExternalE2EEKeyProvider, Room, RoomConnectOptions, RoomOptions, VideoPresets } from 'livekit-client';
import { decodePassphrase } from './lib/client-utils';
import '@livekit/components-styles';
import { Image, Grid } from '@mantine/core';
import PatientDetails from '../dashboard/Telehealth/PatientDetails';
import { useAppContext } from '../AppProvider';
import CallSessionEnded from '../dashboard/Telehealth/CallSessionEnded';
import './Livekit.css';
import { useLocation } from 'react-router-dom';
import { getAppointmentAppointmentById } from '../utils/CustomAPI';
import { useMedplum } from '@medplum/react';
import { getConfig } from '../config';
const configMeduPlum = getConfig();

const LiveKitMeeting = (): JSX.Element => {
  const [isRightSectionVisible, setIsRightSectionVisible] = useState(false);
  const { setHostParticipants, setJoinUser } = useAppContext();
  const [isSessionEnded, setIsSessionEnded] = useState(false);
  const location = useLocation();
  const { appointmentData } = location?.state || {};

  const [token, setToken] = useState('');
  const medplum = useMedplum();
  const [appointmentDetails, setAppointmentDetails] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      const appointmentId = appointmentData?.id || appointmentData?.appointmentId;
      const appointmentToUse = appointmentDetails || (await fetchAppointmentDetails(appointmentId));

      if (appointmentToUse) {
        const liveKitToken = appointmentToUse?.identifier?.find(
          (item: any) => item.system === 'http://livekit-meeting'
        )?.value;

        if (liveKitToken) {
          try {
            const token = JSON.parse(liveKitToken);
            setToken(token?.patient_join_url?.split('/')[4] || '');
          } catch (error) {
            console.error('Invalid JSON in liveKitToken:', error);
            setToken('');
          }
        } else {
          setToken('');
        }
      } else {
        setToken('');
      }
    };

    fetchData().catch((err) => {
      console.error('Error fetching appointment details', err);
    });
  }, [appointmentDetails, appointmentData]);

  // Fetch appointment details
  const fetchAppointmentDetails = async (appointmentId: string): Promise<any> => {
    try {
      const appointment = await medplum.readResource('Appointment', appointmentId);
      setAppointmentDetails(appointment);
      return appointment;
    } catch (error) {
      console.error('Error fetching appointment details:', error);
      return null;
    }
  };

  const worker = typeof window !== 'undefined' && new Worker(new URL('livekit-client/e2ee-worker', import.meta.url));
  const keyProvider = new ExternalE2EEKeyProvider();

  const e2eePassphrase =
    typeof window !== 'undefined' ? decodePassphrase(window.location.hash.substring(1)) : undefined;
  const e2eeEnabled = !!(e2eePassphrase && worker);
  const roomOptions = useMemo((): RoomOptions => {
    return {
      publishDefaults: {
        videoSimulcastLayers: [VideoPresets.h540, VideoPresets.h216],
        red: !e2eeEnabled,
      },
      adaptiveStream: { pixelDensity: 'screen' },
      dynacast: true,
      e2ee: e2eeEnabled
        ? {
            keyProvider,
            worker,
          }
        : undefined,
    };
  }, [e2eeEnabled]);

  const room = useMemo(() => new Room(roomOptions), [roomOptions]);
  if (e2eeEnabled) {
    keyProvider.setKey(e2eePassphrase);
    room.setE2EEEnabled(true);
  }
  const connectOptions = useMemo((): RoomConnectOptions => {
    return {
      autoSubscribe: true,
    };
  }, []);

  useEffect(() => {
    // Handle user connection and start recording
    const handleConnected = async () => {
      setJoinUser(room?.localParticipant?.name ?? '');
      room.remoteParticipants.forEach((participant) => {
        setHostParticipants({ isHost: true, hostName: participant.name ?? '', isSpeaking: participant.isSpeaking });
      });
    };

    // whene host left the call Handle user disconnection and stop recording
    const handleDisconnected = () => {
      console.log('Disconnected from room');
      setIsSessionEnded(true);
      setIsRightSectionVisible(false);
      getAppointmentAppointmentById(medplum, appointmentData.id)
        .then((response) => {
          const details = response.data;
          setAppointmentDetails(details);
        })
        .catch((error) => console.error(error));
    };

    if (room) {
      room.on('connected', handleConnected);
      room.on('disconnected', handleDisconnected);
    }

    return () => {
      if (room) {
        room.off('connected', handleConnected);
        room.off('disconnected', handleDisconnected);
      }
    };
  }, [room]);

  const renderEndSession = (): JSX.Element => {
    return <CallSessionEnded appointmentDetails={appointmentDetails} />;
  };

  return (
    <>
      <Grid className="tw-flex tw-flex-col tw-h-screen tw-p-0 tw-m-[15px] tw-overflow-hidden">
        <Grid.Col span={12} style={{ display: 'flex', position: 'relative', overflow: 'hidden' }}>
          <Grid.Col
            span={isRightSectionVisible ? 7.5 : 12}
            className="tw-transition-[flex] tw-duration-300 tw-h-screen tw-flex tw-flex-col tw-overflow-hidden tw-relative tw-m-0 tw-p-[10px]"
          >
            {!isSessionEnded && token ? (
              <LiveKitRoom
                room={room}
                token={token}
                connectOptions={connectOptions}
                serverUrl={configMeduPlum.liveKitServerUrl}
                audio={true}
                video={true}
                style={{ height: '90%' }}
              >
                <VideoConference />
                <RoomAudioRenderer />
              </LiveKitRoom>
            ) : !token ? (
              <div style={{ width: '100%', height: '90%' }}></div>
            ) : (
              renderEndSession()
            )}

            {!isSessionEnded && (
              <div className="tw-relative tw-w-full tw-h-20 tw-m-0 twp-4 tw-bg-purple-50 tw-flex tw-justify-end tw-items-center tw-overflow-x-hidden">
                <Image
                  width={48}
                  height={48}
                  style={{
                    cursor: 'pointer',
                  }}
                  src={isRightSectionVisible ? '../img/hide-button.svg' : '../img/import.svg'}
                  alt={isRightSectionVisible ? 'Close Sidebar' : 'Open Sidebar'}
                  title={isRightSectionVisible ? 'Close Sidebar' : 'Open Sidebar'}
                  onClick={() => setIsRightSectionVisible(!isRightSectionVisible)}
                />
              </div>
            )}
          </Grid.Col>
          {isRightSectionVisible && (
            <Grid.Col
              span={5}
              className="tw-p-4 tw-border-l tw-border-gray-300 tw-h-full tw-w-full tw-overflow-y-auto tw-flex"
            >
              <Grid>
                <Grid.Col span={12}>
                  <PatientDetails />
                </Grid.Col>
              </Grid>
            </Grid.Col>
          )}
        </Grid.Col>
      </Grid>
    </>
  );
};

export default LiveKitMeeting;

import { Box, Button, Loader } from '@mantine/core';
import { useBrandSettings } from '../../BrandContext';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMedplum } from '@medplum/react-hooks';

interface JoinCallProps {
  opened: boolean;
  close: () => void;
  appointment: any;
}

const JoinCall = ({ opened, close, appointment }: JoinCallProps) => {
  const medplum = useMedplum();
  const brandDetails = useBrandSettings();
  const navigate = useNavigate();
  const [appointmentDetails, setAppointmentDetails] = useState<any>(null);
  const [providerName, setProviderName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false); // Add loading state

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loader
      try {
        let appointmentData;
        if (typeof appointment === 'string') {
          appointmentData = await fetchAppointmentDetails(appointment);
        } else if (typeof appointment === 'object') {
          appointmentData = appointment;
        } else {
          console.error('Invalid appointment data', appointment);
          return;
        }

        setAppointmentDetails(appointmentData);

        setProviderName(
          appointmentData?.participant?.find(
            (participant: any) =>
              participant.actor?.reference?.includes('Practitioner') ||
              participant.actor?.reference?.includes('Parctitioner')
          )?.actor?.display || ''
        );
      } catch (err) {
        console.error('Error fetching appointment details', err);
      } finally {
        setLoading(false); // Stop loader after data fetching completes
      }
    };

    fetchData();
  }, [appointment]);

  const fetchAppointmentDetails = async (appointmentId: string): Promise<any> => {
    try {
      const appointment = await medplum.readResource('Appointment', appointmentId);
      return appointment;
    } catch (error) {
      console.error('Error fetching appointment details:', error);
      return null;
    }
  };

  const patientLabel = `${medplum.getProfile()?.name?.[0]?.given?.join(' ') || ''} ${
    medplum.getProfile()?.name?.[0]?.family || ''
  }`.trim();

  return (
    <Box>
      {loading ? (
        <Box className="tw-flex tw-justify-center tw-items-center tw-py-8">
          <Loader size="lg" color="blue" />
        </Box>
      ) : (
        <>
          <div className="tw-p-4 tw-mt-4 tw-flex tw-gap-8">
            <div className="tw-mb-4 tw-flex tw-flex-col tw-items-start tw-gap-8">
              <span className="tw-font-[500] tw-text-[#000000] tw-text-[14px]">Provider</span>
              <span className="tw-font-[500] tw-text-[#000000] tw-text-[14px]">Patient Name</span>
              <span className="tw-font-[500] tw-text-[#000000] tw-text-[14px]">Appointment Date & Time</span>
              <span className="tw-font-[500] tw-text-[#000000] tw-text-[14px]">Duration</span>
            </div>
            <div className="tw-mb-4 tw-flex tw-flex-col tw-items-start tw-gap-8">
              <span className="tw-font-[400] tw-text-[#344054] tw-text-[14px] tw-flex-grow tw-text-start">
                {providerName || '-'}
              </span>
              <span className="tw-font-[400] tw-text-[#344054] tw-text-[14px] tw-flex-grow tw-text-center">
                {patientLabel || 'Not available'}
              </span>
              <span className="tw-font-[400] tw-text-[#344054] tw-text-[14px] tw-flex-grow tw-text-center">
                {appointmentDetails?.start
                  ? `${new Date(appointmentDetails?.start).getDate()} ${new Date(
                      appointmentDetails?.start
                    ).toLocaleString('en-US', {
                      month: 'short',
                      year: 'numeric',
                    })} ${new Date(appointmentDetails?.start).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      hour12: true,
                    })}`
                  : '-'}
              </span>
              <span className="tw-font-[400] tw-text-[#344054] tw-text-[14px] tw-flex-grow tw-text-center">
                {appointmentDetails?.minutesDuration ? `${appointmentDetails?.minutesDuration} min` : '-'}
              </span>
            </div>
          </div>

          <Box className="telehealth-create-meeting-btn-container">
            <Button
              size="md"
              onClick={close}
              bg="white"
              mt={10}
              radius={4}
              variant="default"
              fullWidth
              className="telehealth-cancel-btn"
            >
              Cancel
            </Button>
            <Button
              className="common-btn"
              radius={4}
              mt={10}
              fullWidth
              type="button"
              style={{ backgroundColor: brandDetails?.buttonColor }}
              onClick={() => {
                navigate(`/telehealth/meeting`, {
                  state: {
                    appointmentData: appointmentDetails,
                  },
                });
              }}
            >
              Join Video Call
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default JoinCall;

/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { IconChevronRight } from '@tabler/icons-react';
import React, { useCallback, useEffect } from 'react';
import { useMedplum } from '@medplum/react';
import { format } from 'date-fns';
import { Skeleton } from '@mantine/core';
import { capitalize } from '@medplum/core';
import { useNavigate } from 'react-router-dom';
import { useBrandSettings } from '../BrandContext';

const UpcomingAppointments = () => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const brandDetails = useBrandSettings();
  const [nextAppointment, setNextAppointment] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const getAppointments = useCallback(() => {
    // Get appointments for the patient
    setIsLoading(true);
    medplum
      .search('Appointment' as 'Appointment', {
        _count: 10,
        _offset: 0, // Add your offset value here
        _sort: '-_lastUpdated',
        _total: 'accurate',
        patient: `Patient/${medplum.getProfile()?.id}`,
      })
      .then((res: any) => {
        setIsLoading(false);
        const now = new Date();
        const filterNextAppointment = res.entry?.filter(
          (appointment: { resource: { status: string; start: string | number | Date } }) =>
            appointment.resource.status !== 'cancelled' &&
            new Date(appointment.resource.start).getTime() > now.getTime()
        );
        setNextAppointment(filterNextAppointment);
      })
      .catch((err: any) => {
        console.error(err);
      });
  }, [medplum, medplum.getProfile()?.id]);

  useEffect(() => {
    if (medplum.getProfile()?.id) {
      getAppointments();
    }
  }, [medplum.getProfile()?.id, getAppointments]);

  return (
    <div className="upcomming-card tw-mt-6 tw-block tw-w-[50%] tw-bg-white tw-border border-[#EAECF0] tw-rounded-lg tw-h-[400px] scrollable_div tw-p-5">
      <div className="tw-flex tw-items-center tw-justify-between">
        <h5 className="tw-text-[16px] tw-font-semibold tw-text-[#101828] tw-mt-[8px]">
          Upcoming Appointments ({nextAppointment?.length})
        </h5>
        {brandDetails?.brandName !== 'MyWellness ID EPIC Production Org' && (
          <span
            className="tw-text-[#344054] tw-font-medium tw-mt-[8px] tw-gap-2 tw-flex tw-items-center tw-cursor-pointer"
            onClick={() =>
              brandDetails?.domainName === 'patients.healthside.dev' ? navigate('/telehealth') : navigate('/Appointment')
            }
          >
            View all <IconChevronRight size={15} />
          </span>
        )}
      </div>
      <hr className="h-px tw-my-4 bg-gray-200 border-0 dark:bg-gray-700"></hr>
      {!isLoading ? (
        nextAppointment?.length > 0 ? (
          nextAppointment.map((appointment, index) => (
            <div className="" key={index}>
              <div className="tw-border border-[#F2F2F2] tw-rounded-lg tw-mt-2">
                <div className="tw-flex tw-gap-2 tw-p-2">
                  <button className="tw-border tw-border-[#D0D5DD] tw-px-2 tw-py-0.5 tw-rounded-[6px] tw-font-medium tw-text-[#344054] tw-text-[12px]">
                    Video Call
                  </button>
                  <span className="tw-bg-[#CEFCD2] tw-text-[#344054] tw-text-xs tw-font-medium  tw-px-2.5 tw-py-1 tw-rounded-[6px] tw-border">
                    {capitalize(appointment?.resource?.status)}
                  </span>
                </div>
                <div className="tw-flex tw-flex-row tw-p-2 tw-mt-1 tw-ms-3 tw-gap-3 tw-items-center">
                  <img className="tw-w-12 tw-h-12 tw-rounded-full" src="./img/profile.png" alt="Rounded avatar" />
                  <div>
                    <span className="tw-text-[16px] tw-font-semibold tw-text-[#101828]">
                      {appointment?.resource.serviceCategory?.[0].coding?.[0].display}
                    </span>
                    <div className="tw-py-1">
                      <div className="tw-flex tw-justify-between">
                        <span className="tw-text-[14px] tw-text-[#475467] tw-me-1">
                          {' '}
                          {format(new Date(appointment?.resource?.start), 'MMM dd yyyy hh:mm a').replace(
                            'yyyy',
                            new Date(appointment?.resource?.start).getFullYear().toString()
                          )}{' '}
                          -{format(new Date(appointment?.resource?.end), 'hh:mm a')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <span
                    className="tw-text-[14px] tw-text-[#9552E8] tw-text-right"
                    style={{ color: brandDetails?.textColor }}
                  >
                    {
                      appointment?.resource?.participant?.find((participant: { actor: { reference: string } }) =>
                        participant.actor?.reference?.startsWith('Practitioner')
                      )?.actor?.display
                    }
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="tw-text-center tw-font-semibold tw-mt-5 tw-text-[14px] tw-text-[#475467]">
            No upcoming appointments
          </div>
        )
      ) : (
        <div className="tw-grid tw-text-center tw-mt-[20px] tw-block tw-m-auto">
          <Skeleton height={8} mb="xs" />
          <Skeleton height={8} mb="xs" />
          <Skeleton height={8} mb="xs" />
          <Skeleton height={8} mb="xs" />
        </div>
      )}
    </div>
  );
};

export default UpcomingAppointments;

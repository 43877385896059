/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useRef, useState } from 'react';
import { Text, Group, ScrollArea, Button, Textarea, Divider, Box, Paper, Skeleton, Avatar } from '@mantine/core';
import { IconArrowNarrowLeft, IconRefresh, IconVideo } from '@tabler/icons-react';
import ChatMessage from './ChatMessage';
import { useNavigate, useParams } from 'react-router-dom';
import { ResourceAvatar, useMedplum } from '@medplum/react';
import { getDayLabel, getFullName } from '../../utils/util';
import { Communication, Patient, Practitioner } from '@medplum/fhirtypes';
import { useBrandSettings } from '../../BrandContext';

interface ChatMessageType {
  id: string;
  sender: 'provider' | 'patient';
  message: string;
  time: string;
  date?: string;
  day?: string;
  subject?: { reference: string; display: string };
}

const Messages: React.FC = () => {
  const medplum = useMedplum();
  const profile = medplum.getProfile() as Patient;
  const navigate = useNavigate();
  const { id } = useParams();
  const [messages, setMessages] = useState<ChatMessageType[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [communication, setCommunication] = useState<Communication>();
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const brandDetails = useBrandSettings();
  const [lastMessageTimestamp, setLastMessageTimestamp] = useState<string | null>(null);

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTo({
        top: scrollAreaRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messages]);

  useEffect(() => {
    getMessages();
    const interval = setInterval(() => {
      getMessages();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  // get messages from communication resource and set messages state
  const getMessages = async () => {
    setLoading(true);
    try {
      const resource = await medplum.readResource('Communication', id || '');
      setCommunication(resource);

      const parsedMessages: ChatMessageType[] = (resource.payload || [])
        ?.map((item) => {
          const parsed = JSON.parse(item.contentString || '{}');
          const date = new Date(parsed.date);
          return Object.entries(parsed)
            .filter(([key]) => key === 'patient' || key === 'provider')
            .map(([key, value]) => ({
              id: parsed.id + '-' + key,
              sender: key as 'provider' | 'patient',
              message: value as string,
              time: date.toLocaleTimeString(),
              day: getDayLabel(parsed.date),
              date: parsed.date,
              subject: resource.subject
                ? { reference: resource.subject.reference || '', display: resource.subject.display || '' }
                : undefined,
            }));
        })
        .flat()
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()); // Sort by date

      // Get only new messages
      const newMessages = lastMessageTimestamp
        ? parsedMessages.filter(
            (msg) => msg.date && new Date(msg.date).getTime() > new Date(lastMessageTimestamp).getTime()
          )
        : parsedMessages;

      if (newMessages.length > 0) {
        setMessages((prev) => {
          const existingIds = new Set(prev.map((msg) => msg.id));
          const uniqueMessages = newMessages.filter((msg) => !existingIds.has(msg.id));

          return [...prev, ...uniqueMessages];
        });

        setLastMessageTimestamp(newMessages[newMessages.length - 1].date || null);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async () => {
    setLoading(false);
    if (!newMessage.trim()) {
      return;
    }

    const patientName = medplum?.getProfile()?.name?.[0];

    const newMsg: ChatMessageType = {
      id: Date.now().toString(), // Temporary ID
      sender: 'patient',
      message: newMessage,
      time: 'Just now',
      day: getDayLabel(new Date().toISOString()),
      date: new Date().toISOString(),
    };

    setNewMessage('');

    try {
      // Update Medplum Communication Resource
      await medplum.updateResource<Communication>({
        resourceType: 'Communication',
        id: id || '',
        status: 'in-progress',
        sender: {
          reference: `Patient/${medplum.getProfile()?.id}`,
          display: patientName ? getFullName(patientName) : 'Unknown',
        },
        category: communication?.category,
        subject: communication?.subject,
        recipient: [
          {
            reference: profile.generalPractitioner?.[0]?.reference || '',
            display: profile.generalPractitioner?.[0]?.display || 'Unknown',
          },
        ],
        sent: communication?.sent,
        payload: [
          ...(communication?.payload || []),
          {
            contentString: JSON.stringify({
              date: newMsg.date,
              patient: newMessage,
              id: newMsg.id,
            }),
          },
        ],
      });

      // Fetch updated messages after API update
      getMessages();
    } catch (err) {
      console.log(err);
      // If API fails, add message locally to avoid missing messages
      setMessages((prev) => [...prev, newMsg]);
    }
  };

  const getInitials = (name: string) => {
    if (!name || name.trim() === '') return 'N/A';

    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase();
  };

  return (
    <Paper radius="12px">
      <Box style={{ width: '100%' }}>
        {/* Header */}
        <Group py="md" justify="space-between" style={{ borderBottom: '1px solid #e0e0e0', padding: '20px' }}>
          <Group align="center" className="tw-gap-4">
            <button
              className="tw-rounded-[50%] tw-border tw-border-[#D0D5DD] tw-p-1.5 right_card tw-cursor-pointer"
              onClick={() => navigate(`/Communication`)}
              style={{ alignSelf: 'center' }}
            >
              <IconArrowNarrowLeft size={20} stroke={1.5} color="#344054" />
            </button>

            <Group align="center" className="tw-gap-3">
              <Avatar radius="xl" size={50} style={{ backgroundColor: '#F9FAFB' }}>
                {getInitials(profile.generalPractitioner?.[0]?.display || communication?.sender?.display || '')}
              </Avatar>
              <div>
                <Text size="lg" fw={600}>
                  {profile.generalPractitioner?.[0]?.display || communication?.sender?.display || ''}
                </Text>
              </div>
            </Group>
          </Group>

          <Group>
            <button
              className="tw-rounded-lg tw-border tw-border-[#D0D5DD] tw-p-1.5 right_card tw-cursor-pointer"
              onClick={getMessages}
            >
              <IconRefresh size={20} stroke={1.5} color="#344054" />
            </button>
            <button
              className="tw-rounded-lg tw-border tw-border-[#D0D5DD] tw-p-1.5 right_card tw-cursor-pointer"
              onClick={() => navigate(`/Telehealth`)}
            >
              <IconVideo size={20} stroke={1.5} color="#344054" />
            </button>
          </Group>
        </Group>

        {/* Chat Area */}

        <Box p="md" h={480} style={{ border: '1px solid #e0e0e0', borderRadius: '8px', margin: 25 }}>
          <ScrollArea h={480} py="md" viewportRef={scrollAreaRef} scrollbarSize={0}>
            {loading ? (
              <Box mb="sm">
                <Skeleton height={40} mt={6} width="30%" radius="md" mb={20} />
                <Group justify="flex-end">
                  <Skeleton height={40} mt={6} width="20%" radius="md" style={{ display: 'block', float: 'right' }} />
                </Group>
                <Skeleton height={40} mt={6} width="25%" radius="md" mb={20} />
                <Group justify="flex-end">
                  <Skeleton height={40} mt={6} width="40%" radius="md" style={{ display: 'block', float: 'right' }} />
                </Group>
                <Skeleton height={40} mt={6} width="35%" radius="md" mb={20} />
                <Group justify="flex-end">
                  <Skeleton height={40} mt={6} width="25%" radius="md" style={{ display: 'block', float: 'right' }} />
                </Group>
              </Box>
            ) : (
              messages.map((msg, index) => {
                const showDivider = index === 0 || msg.day !== messages[index - 1].day;
                return (
                  <React.Fragment key={msg.id}>
                    {showDivider && <Divider label={msg.day} labelPosition="center" my="xs" />}
                    <ChatMessage
                      sender={msg.sender}
                      message={msg.message}
                      time={msg.time}
                      subject={msg.subject}
                      providerName={profile.generalPractitioner?.[0]?.display || communication?.sender?.display || ''}
                    />
                  </React.Fragment>
                );
              })
            )}
          </ScrollArea>
        </Box>

        {/* Message Input */}
        <Group
          px="md"
          py="sm"
          style={(theme) => ({
            borderTop: `1px solid ${theme.colors.gray[3]}`,
            backgroundColor: theme.white,
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            position: 'fixed',
            bottom: 0,
            background: '#fff',
            width: '-webkit-fill-available',
          })}
          className="chat-input"
        >
          <Textarea
            name="message"
            placeholder={'Send a message...'}
            rightSectionWidth={42}
            value={newMessage}
            onChange={(e) => setNewMessage(e.currentTarget.value)}
            minRows={2}
            autosize
            style={{
              flex: 1,
              '.mantine-TextInput-input': {
                paddingRight: '70px',
                paddingLeft: '20px',
                height: '80px',
              },
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                sendMessage();
              }
            }}
            rightSection={
              <Group>
                <Button
                  radius="md"
                  onClick={sendMessage}
                  style={{
                    height: '35px',
                    paddingInline: '15px',
                    backgroundColor: brandDetails?.buttonColor || '#3CA5A9',
                    fontSize: '14px',
                    fontWeight: '500',
                    color: 'white',
                  }}
                >
                  Send
                </Button>
              </Group>
            }
          />
        </Group>
      </Box>
    </Paper>
  );
};

export default Messages;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AreaChart, Area, Tooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts';
// import { IconArrowUp } from '@tabler/icons-react';
import { DataPoint } from '../../interfaces/DataPoint';
import { Skeleton, Modal } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { useEffect, useState } from 'react';
import { getHeartRate } from '../../utils/CustomAPI';
import { IconArrowDown, IconArrowUp, IconExternalLink } from '@tabler/icons-react';
import { formatDate } from '../../utils/util';


const HeartRate = () => {
  const medplum = useMedplum();
  const [heartRateData, setHeartRateData] = useState<DataPoint[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [heartRateAvg, setHeartRateAvg] = useState<number>(0);
  const [percentageChange, setPercentageChange] = useState('0');
  const [latestHeartRate, setLatestHeartRate] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to remove duplicate entries
  const removeDuplicates = (data: DataPoint[]) => {
    const uniqueData = data.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.date === value.date && t.value === value.value
      ))
    );
    return uniqueData;
  };

  useEffect(() => {
    //get the previous month date
    setIsLoading(true);
    const previousMonth = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString();
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    getHeartRate(medplum, medplum.getProfile()?.id || '', previousMonth).then((res) => {
      setIsLoading(false);
      const filteredData = res?.data?.entry.filter((entry: any) => {
        const effectiveDate = entry.resource.effectiveDateTime.split('T')[0];
        return effectiveDate === today;
      });
      setLatestHeartRate(filteredData?.[0]?.resource.valueQuantity?.value || 0);
      const data = res?.data.entry
        .map((entry: any) => ({
          value: entry.resource.valueQuantity?.value,
          unit: entry.resource.valueQuantity?.unit.toString(),
          date: entry.resource.effectiveDateTime,
        }))
        .filter((entry: DataPoint) => new Date(entry.date) <= new Date()) // Filter out future dates
        .sort((a: DataPoint, b: DataPoint) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .map((entry: DataPoint) => ({
          ...entry,
          date: formatDate(entry.date),
        }));
      const uniqueData = removeDuplicates(data); // Remove duplicate entries
      setHeartRateData(uniqueData);
      const heartRate = res?.data.entry
        .slice(1)
        .map((entry: any) => entry.resource.valueQuantity?.value)
        .filter((value: number | undefined) => value !== undefined && value !== 0) || [];
      if (heartRate.length > 0) {
        const sum = heartRate.reduce((a: number, b: number) => a + b, 0);
        const avg = sum / heartRate.length;
        setHeartRateAvg(Number(avg.toFixed(2)));
        // Get the first recorded heart rate
        const initialHeartRate = heartRate[0];
      
        // Compute percentage change
        const percentageChange = Math.abs(((avg - initialHeartRate) / initialHeartRate) * 100);

        setPercentageChange(percentageChange.toFixed(2));
      }
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
    });
  }, []);
  return (
    <div className="tw-mt-6 xs:tw-mt-2 tw-px-5 tw-py-4 tw-block tw-w-[50%] tw-h-fit tw-bg-white tw-border border-[#F2F2F2] tw-rounded-[12px] featured-card">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
        <h5 className="tw-text-[16px] tw-font-semibold tw-tracking-tight tw-text-[#101828] tw-pb-0">Heart Rate</h5>
        {heartRateData.length > 0 && (
          <IconExternalLink
            size={20}
            className="tw-cursor-pointer tw-text-[#667085]"
            title="Click to see a detailed heart rate graph."
            onClick={() => setIsModalOpen(true)}
          />
        )}
      </div>
      {!isLoading ? (
        <div className="tw-flex tw-justify-between tw-mt-5">
          <div className="tw-grid tw-w-[50%]">
            <span className="tw-font-semibold tw-text-[#101828] tw-text-[22px] tw-mb-3">
              {latestHeartRate || 0} bpm
            </span>
            <span className='tw-text-[#067647] tw-flex tw-items-center tw-font-medium' style={{
              color: heartRateAvg > heartRateData?.[0]?.value || heartRateAvg === 0.00 ? '#17B26A' : '#FF5C58',
            }}>
              {heartRateAvg > heartRateData?.[0]?.value || heartRateAvg === 0.00 ? <IconArrowUp size={15} color='#17B26A'/> : <IconArrowDown size={15} color='#FF5C58'/>}
              {percentageChange}%
              <span className='tw-ml-1 tw-text-[#475467] tw-font-medium'>vs last month</span>
            </span>
          </div>
          <div className="tw-w-[50%]">
            <ResponsiveContainer>
              <AreaChart data={heartRateData} barSize={5}>
                <defs>
                  <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={heartRateAvg > heartRateData?.[0]?.value || heartRateAvg === 0.00 ? "#17B26A" : '#FF5C58'} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={heartRateAvg > heartRateData?.[0]?.value ? "#17B26A" : '#FF5C58'} stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="date" tickLine={false} display='none'/>
                <Tooltip 
                  cursor={false}
                />
                <Area 
                  dataKey="value" 
                  fill="url(#colorUv2)" stroke={heartRateAvg > heartRateData?.[0]?.value || heartRateAvg === 0.00 ? "#17B26A" : '#FF5C58'} 
                  strokeWidth={2} 
                  name="Heart Rate (bpm)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className="tw-grid tw-text-center tw-mt-[20px] tw-block tw-m-auto">
          <Skeleton height={8} mb="xs" />
          <Skeleton height={8} mb="xs" />
          <Skeleton height={8} mb="xs" />
        </div>
      )}
      {/* Modal for Enlarged Chart */}
      <Modal opened={isModalOpen} onClose={() => setIsModalOpen(false)} title={<span style={{ fontSize: '16px', fontWeight: 600 }}>Heart Rate</span>} size="lg" centered>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={heartRateData} margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
            <XAxis
              dataKey="date"
              tickFormatter={(tick) => {
                const date = new Date(tick.split('/').reverse().join('-'));
                return `${date.getDate()}\n${date.toLocaleDateString('en-US', { month: 'short' })}`;
              }}
              interval={0}
              angle={-25}
              dy={10}
              tick={{ fontSize: 10, textAnchor: "middle" }}
            />
            <YAxis tick={{ fontSize: 10 }} />
            <Tooltip cursor={false} />
            <Area
              dataKey="value"
              fill="url(#colorUv2)" stroke={heartRateAvg > heartRateData?.[0]?.value || heartRateAvg === 0.00 ? "#17B26A" : '#FF5C58'}
              strokeWidth={2}
              name="Heart Rate (BPM)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Modal>
    </div>
  );
};

export default HeartRate;

import { Divider, Paper, Text } from '@mantine/core';
import { IconCornerUpLeft } from '@tabler/icons-react';
import { useState } from 'react';
import { useBrandSettings } from '../../BrandContext';
import { format } from 'date-fns';

interface CallSessionEndedProps {
  appointmentDetails: any;
}

const CallSessionEnded = ({ appointmentDetails }: CallSessionEndedProps): JSX.Element => {
  const brandDetails = useBrandSettings();
  const providerName =
    appointmentDetails?.participant?.find((participant: any) => participant?.actor?.reference?.includes('Practitioner'))
      ?.actor?.display || '-';
  const dateOfSession = appointmentDetails?.start
    ? `${new Date(appointmentDetails.start).getDate()} ${new Date(appointmentDetails.start).toLocaleString('en-US', {
        month: 'short',
        year: 'numeric',
      })}`
    : '-';
  const minutesDuration = appointmentDetails?.minutesDuration || '-';
  const startTime = appointmentDetails?.start ? format(new Date(appointmentDetails.start), 'hh:mm A') : '-';
  const endTime = appointmentDetails?.end ? format(new Date(appointmentDetails.end), 'hh:mm A') : '-';

  return (
    <>
      <Paper p="10px" bg={'#FFFFFF'} h={'95vh'}>
        <div className="tw-grid tw-grid-cols-12 tw-gap-4 tw-h-full">
          <div className="tw-col-span-12 tw-lg:col-span-12 tw-bg-[#F9F5FF] tw-p-6 tw-rounded-md">
            <Text className="tw-text-[#101828] tw-ml-5 tw-font-semibold tw-text-[18px]">Session Overview</Text>
            <Text className="tw-text-[#475467] tw-ml-5 tw-font-[400] tw-text-[14px] tw-mb-2">
              Your telehealth session has been completed with{' '}
              <span style={{ color: brandDetails?.backgroundColor, fontWeight: '500', fontSize: '16px' }}>
                {'Dr ' + providerName}
              </span>
            </Text>
            <Divider mb={'xl'} className="tw-ml-5" />
            <div className="tw-flex tw-justify-between tw-ml-5 tw-items-center">
              <div className="tw-block tw-items-center">
                <div>
                  <Text className="tw-text-[#475467] tw-font-[400] tw-text-[14px]">Provider</Text>
                  <Text className="tw-text-[#344054] tw-font-[500] tw-text-[14px]">{providerName}</Text>
                </div>
                <div className="tw-flex tw-gap-10 tw-mt-8">
                  <div>
                    <Text className="tw-text-[#475467] tw-font-[400] tw-text-[14px]">Date of Session</Text>
                    <Text className="tw-text-[#344054] tw-font-[500] tw-text-[14px]">{dateOfSession}</Text>
                  </div>
                  <div>
                    <Text className="tw-text-[#475467] tw-font-[400] tw-text-[14px]">Time of Session</Text>
                    <Text className="tw-text-[#344054] tw-font-[500] tw-text-[14px]">
                      {startTime} - {endTime} - ({minutesDuration} min)
                    </Text>
                  </div>
                </div>
              </div>
              <div className="tw-block tw-items-center"></div>
            </div>
            <div className="tw-flex tw-items-center tw-mt-10 tw-ml-5">
              <button
                className="tw-flex tw-items-center tw-bg-white tw-border tw-border-gray-300 tw-text-[#344054] tw-font-[500] tw-px-4 tw-py-2 tw-rounded-md hover:tw-bg-gray-100 tw-h-10 tw-w-fit"
                onClick={() => window.location.replace(`/telehealth`)}
              >
                <IconCornerUpLeft size={20} color="#667085" className="tw-mr-2" />
                Return to home
              </button>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default CallSessionEnded;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { createContext, useContext, ReactNode, useState } from 'react';
import { FhirBotDetail } from './dashboard/AIAssistant/interfaces';

interface AppContextProps {
  isCheckPageRefresh: boolean;
  setIsCheckPageRefresh: (value: boolean) => void;
  setHostParticipants: (value: { isHost: boolean; hostName: string; isSpeaking: boolean }) => void;
  hostParticipants: { isHost: boolean; hostName: string; isSpeaking: boolean };
  setJoinUser: (value: string) => void;
  joinUser: string;
  scheduleId: string;
  fhirBotDetailsUpdated: FhirBotDetail[]; // Added
  setfhirBotDetailsUpdatedUpdated: (value: FhirBotDetail[]) => void; // Added
  setSessionId: (value: string) => void;
  sessionId: string;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isCheckPageRefresh, setIsCheckPageRefresh] = useState(false);
  const [hostParticipants, setHostParticipants] = useState<{ isHost: boolean; hostName: string; isSpeaking: boolean }>({
    isHost: false,
    hostName: '',
    isSpeaking: false,
  });
  const [joinUser, setJoinUser] = useState('');
  const [scheduleId, setScheduleId] = useState('');
  const [fhirBotDetailsUpdated, setfhirBotDetailsUpdatedUpdated] = useState<FhirBotDetail[]>([]); // Added
  const [sessionId, setSessionId] = useState('');
  return (
    <AppContext.Provider
      value={React.useMemo(
        () => ({
          isCheckPageRefresh,
          setIsCheckPageRefresh,
          setHostParticipants,
          hostParticipants,
          setJoinUser,
          joinUser,
          scheduleId,
          setScheduleId,
          fhirBotDetailsUpdated, // Added
          setfhirBotDetailsUpdatedUpdated, // Added
          sessionId,
          setSessionId,
        }),
        [
          isCheckPageRefresh,
          setHostParticipants,
          hostParticipants,
          setJoinUser,
          joinUser,
          scheduleId,
          setScheduleId,
          fhirBotDetailsUpdated, // Added
          setfhirBotDetailsUpdatedUpdated,
          sessionId,
          setSessionId,
        ]
      )}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

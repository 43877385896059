export const MyWellness_ID_EPIC_Production_Org = 'MyWellness ID EPIC Production Org';
export const how_can = 'How can I help you with';
export const my_wellness_ai = 'My Wellness AI';
export const AI_Assistant = 'AI Assistant';
export const ai_powered =
  'Providing AI-powered insights—always consult your healthcare provider for medical decisions.';
export const new_ai_search = 'New AI Search';
export const new_button = 'New';
export const liked = 'Liked';
export const disliked = 'Disliked';
export const chatgpt_session_id = 'http://chatgpt-session-id';
export const chatgpt_session_title = 'http://chatgpt-session-title';
export const PDF = 'PDF';
export const more = 'More';
export const application_pdf = 'application/pdf';
export const RetrievePatientPDFRecords = 'RetrievePatientPDFRecords';
export const Medical_Records_pdf = '_Medical_Records.pdf';
export const Download = 'Download';
export const patient = 'Patient';
export const RetrieveClinicalNotes = 'RetrieveClinicalNotes';
export const static_question = 'Patient Static First Question';
export const chatgpt = 'chatgpt';
export const write_instruction = 'Write / Record your instruction';
export const sending = 'Sending...';
export const send = 'Send';
export const Today = 'Today';
export const Yesterday = 'Yesterday';
export const api_healthside = 'api/healthside';

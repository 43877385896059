import { useMedplum } from '@medplum/react';
import React, { useEffect, useState, useCallback } from 'react';
import { generatePatientHealthSummary, downloadHealthSummary } from '../../utils/CustomAPI';
import { IconRefresh, IconReportMedical } from '@tabler/icons-react';
import { useBrandSettings } from '../../BrandContext';
import { useAppContext } from '../../AppProvider';
import { Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { formatDate } from '../../utils/util';

const PatientHealthSummary = () => {
  const medplum = useMedplum();
  const { isCheckPageRefresh } = useAppContext();
  const brandDetails = useBrandSettings();
  const [patientHealthSummary, setPatientHealthSummary] = useState<any>(null);
  const [authoredDate, setAuthoredDate] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documentRefId, setDocumentRefId] = useState<string | null>(null);

  const getPatientHealthSummary = useCallback(
    async (refreshSummary: boolean = false) => {
      setIsLoading(true);
      try {
        const response = await generatePatientHealthSummary(
          medplum,
          medplum.getProfile()?.id || '',
          refreshSummary
        );
        const data = response?.data;
        const attachmentData = JSON.parse(data?.content?.[0]?.attachment?.data || '{}');
        setPatientHealthSummary(attachmentData);
        setAuthoredDate(data?.date || '');
        setDocumentRefId(data?.id || null);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [medplum]
  );

  useEffect(() => {
    getPatientHealthSummary();
  }, [getPatientHealthSummary]);

  useEffect(() => {
    if (isCheckPageRefresh) {
      getPatientHealthSummary();
    }
  }, [isCheckPageRefresh, getPatientHealthSummary]);

  const handleDownload = async () => {
    if (documentRefId) {
      try {
        const response = await downloadHealthSummary(medplum, documentRefId);
        if (response) {
          if (response && response.data) {
            const contentDisposition = response?.headers['content-disposition'];
            const match = contentDisposition?.match(/filename="(.+)"/);
            const fileName = match?.[1] || 'AIHealthSummary.pdf';
            const fileType = 'application/pdf';
            const fileExtension = 'pdf';
            const blob = new Blob([response.data], { type: fileType });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            showNotification({ color: 'green', message: `Exported successfully as ${fileExtension.toUpperCase()}` });
          }
        } else {
          throw new Error('Response is null or does not contain data');
        }
      } catch (error) {
        console.error('Error downloading health summary:', error);
      }
    }
  };

  return (
    <div className="tw-bg-[#fff] tw-px-3 tw-pt-5 tw-rounded-b-lg tw-text-[#101828] tw-text-[18px] tw-font-semibold tw-border tw-border-[#EAECF0]">
      {patientHealthSummary &&
        typeof patientHealthSummary === 'object' &&
        Object.keys(patientHealthSummary).length > 0 && (
          <div
            className="tw-px-3"
            style={{
              borderBottom: '1px solid rgb(234, 236, 240)',
              paddingBottom: '10px',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              <h5 style={{ fontSize: '12px', fontWeight: 400 }}>Last Generated</h5>
              <p style={{ fontSize: '12px', fontWeight: 600 }}>{formatDate(authoredDate)}</p>
            </div>
            <Tooltip
              label={
                <div style={{ width: 'auto', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                  <div style={{ fontSize: '12px' }}>Refresh Health Summary</div>
                </div>
              }
              position="top"
              withArrow
            >
              <button type="button" className="tw-ml-3" onClick={() => getPatientHealthSummary(true)}>
                <div
                  className="tw-bg-[#F9F5FF] tw-p-2 tw-h-[55px] tw-w-[55px] tw-rounded-full"
                  style={{
                    background: brandDetails?.secondaryColor,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    className="tw-bg-[#F4EBFF] tw-p-1.5 tw-h-[35px] tw-w-[35px] tw-rounded-full"
                    style={{
                      background: brandDetails?.primaryColor,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IconRefresh color={brandDetails?.iconColor || '#9552E8'} size={20} />
                  </div>
                </div>
              </button>
            </Tooltip>
            <Tooltip
              label={
                <div style={{ width: 'auto', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                  <div style={{ fontSize: '12px' }}>Download Health Report</div>
                </div>
              }
              position="top"
              withArrow
            >
              <button type="button" className="tw-ml-3" onClick={handleDownload}>
                <div
                  className="tw-bg-[#F9F5FF] tw-p-2 tw-h-[55px] tw-w-[55px] tw-rounded-full"
                  style={{
                    background: brandDetails?.secondaryColor,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    className="tw-bg-[#F4EBFF] tw-p-1.5 tw-h-[35px] tw-w-[35px] tw-rounded-full"
                    style={{
                      background: brandDetails?.primaryColor,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IconReportMedical color={brandDetails?.iconColor || '#9552E8'} size={20} />
                  </div>
                </div>
              </button>
            </Tooltip>
          </div>
        )}
      {isLoading ? (
        <span className="loading">
          <p className="tw-text[14px] tw-text-[#475467]">Loading</p>
          <span className="loading__dot"></span>
          <span className="loading__dot"></span>
          <span className="loading__dot"></span>
        </span>
      ) : patientHealthSummary &&
        typeof patientHealthSummary === 'object' &&
        Object.keys(patientHealthSummary).length > 0 ? (
        <div
          className="tab-content assessment_insights_tab scrollable_div"
          style={{ height: '400px', overflowY: 'scroll', marginTop: '10px', marginBottom: '10px' }}
        >
          {Object.keys(patientHealthSummary).map((key, index) => (
            <div key={key}>
              {renderSection(key.replace(/([A-Z])/g, ' $1').trim(), patientHealthSummary[key], index)}
            </div>
          ))}
        </div>
      ) : (
        <p style={{ fontSize: '14px', fontWeight: 500, marginBottom: '8px' }}>No AI Health Summary Found.</p>
      )}
    </div>
  );
};

const renderContent = (content: any, level = 1) => {
  if (content == null) {
    return null;
  }
  if (typeof content === 'string') {
    // Remove leading hyphen and space if present
    const formattedContent = content.replace(/^- /, '');
    return (
      <div style={{ margin: '10px 0', marginLeft: `${level * 3}px` }}>
        <span
          style={{
            fontSize: '14px',
            fontWeight: 500,
            color: '#475467',
            lineHeight: '26px',
          }}
        >
          {'• '}
          {formattedContent}
        </span>
      </div>
    );
  }

  if (Array.isArray(content)) {
    return (
      <div style={{ margin: '10px 0', marginLeft: `${level * 3}px` }}>
        {content.map((item, idx) => (
          <div key={idx} style={{ marginLeft: `${level * 3}px` }}>
            {typeof item === 'object' ? (
              renderContent(item, level + 1)
            ) : (
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: 450,
                  color: '#475467',
                  lineHeight: '26px',
                }}
              >
                {'• '}
                {item.toString().replace(/^- /, '')}
              </span>
            )}
          </div>
        ))}
      </div>
    );
  }

  return Object.keys(content).map((key, index) => {
    const value = content[key];
    const isObject = typeof value === 'object' && value !== null;
    const isArray = Array.isArray(value);
    const marginLeft = level * 3;

    // Handle the "-" key to directly display its value
    if (key === '-') {
      return (
        <div key={index} style={{ marginBottom: '10px', marginLeft: `${marginLeft}px` }}>
          <span
            style={{
              fontSize: '14px',
              marginLeft: `${(level + 1) * 3}px`,
              fontWeight: 500,
              color: '#475467',
              lineHeight: '26px',
            }}
          >
            {value}
          </span>
        </div>
      );
    }

    return (
      <div key={key} style={{ marginLeft: `${marginLeft}px` }}>
        <span style={{ fontWeight: 700, fontSize: '14px', color: '#333' }}>
          {/* Numbered list for top-level items */}
          {level === 1 ? `${index + 1}. ` : '• '}
          {key
            .replace(/([A-Z])/g, ' $1')
            .trim()
            .replace(/\s+/g, ' ')}
          {!isArray && ':'}
        </span>
        {isObject ? (
          <div style={{ margin: '5px 0' }}>
            {isArray
              ? value.map((item: any, idx: number) => (
                  <div key={idx} style={{ marginLeft: `${marginLeft + 3}px` }}>
                    {typeof item === 'object' ? (
                      renderContent(item, level + 1)
                    ) : (
                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: 450,
                          color: '#475467',
                          lineHeight: '26px',
                        }}
                      >
                        {'• '}
                        {item.toString().replace(/^- /, '')}
                      </span>
                    )}
                  </div>
                ))
              : renderContent(value, level + 1)}
          </div>
        ) : (
          <span
            style={{
              fontSize: '14px',
              margin: '10px 5px',
              fontWeight: 450,
              color: '#475467',
              lineHeight: '26px',
            }}
          >
            {value}
          </span>
        )}
      </div>
    );
  });
};

const renderSection = (title: string, content: any, index: number) => (
  <>
    <h5 style={{ fontWeight: 700, fontSize: '16px', color: '#333', marginBottom: '10px', marginTop: '15px' }}>
      {`${index + 1}. ${title.replace(/([A-Z])/g, ' $1').trim()}`}
    </h5>
    {renderContent(content, 2)}
  </>
);

export default PatientHealthSummary;

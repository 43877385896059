import { Text } from '@mantine/core';
import { Chat, Identifier, FhirBotDetail } from './interfaces';
import '../AIAssistant/ChatHistory.css';
import { useBrandSettings } from '../../BrandContext';
import { useMedplum } from '@medplum/react';
import { useAppContext } from '../../AppProvider';
import { chatgpt_session_id, Today, Yesterday, chatgpt_session_title } from '../../utils/constantString';

interface ChatHistoryProps {
  converstionHistoryList: Chat[];
  selectedSessionId: string | null;
  setSelectedSessionId: (id: string) => void;
  setIsSessionLoading: (loading: boolean) => void;
  setLikeUnlike: (sessionId: string) => void;
  setFhirBotDetails: (details: FhirBotDetail[]) => void;
  setHistoryData: any;
}

const ChatHistory: React.FC<ChatHistoryProps> = ({
  converstionHistoryList,
  selectedSessionId,
  setSelectedSessionId,
  setIsSessionLoading,
  setLikeUnlike,
  setFhirBotDetails,
  setHistoryData,
}) => {
  const medplum = useMedplum();
  const brandDetails = useBrandSettings();
  const profileId = medplum.getProfile()?.id;
  const { fhirBotDetailsUpdated, setfhirBotDetailsUpdatedUpdated } = useAppContext();
  const { sessionId, setSessionId } = useAppContext();

  function groupedConverstionHistoryList(historyList: Chat[]): { dateLabel: string; records: Chat[] }[] {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const groups: { dateLabel: string; records: Chat[] }[] = [];

    const sortedHistoryList = [...historyList].sort(
      (a, b) => new Date(b.resource.sent).getTime() - new Date(a.resource.sent).getTime()
    );

    sortedHistoryList.forEach((chat) => {
      const sentDate = new Date(chat.resource.sent);
      let dateLabel = sentDate.toLocaleDateString();

      if (sentDate.toDateString() === today.toDateString()) {
        dateLabel = Today;
      } else if (sentDate.toDateString() === yesterday.toDateString()) {
        dateLabel = Yesterday;
      }

      let group = groups.find((g) => g.dateLabel === dateLabel);
      if (!group) {
        group = { dateLabel, records: [] };
        groups.push(group);
      }
      group.records.push(chat);
    });

    groups.forEach((group) => {
      group.records.sort((a, b) => new Date(b.resource.sent).getTime() - new Date(a.resource.sent).getTime());
    });

    return groups;
  }

  function isJSON(str: string): boolean {
    try {
      JSON.parse(str);
      return true;
    } catch {
      return false;
    }
  }

  return (
    <div className="scrollable-container">
      <div className="history-container" style={{ backgroundColor: brandDetails?.secondaryColor }}>
        <Text className="history-title">History</Text>
        <div className="subtitle-border">
          <Text className="history-subtitle">Explore your recent history</Text>
        </div>
      </div>

      {groupedConverstionHistoryList(converstionHistoryList).map((group, index) => (
        <div key={index}>
          <div className="date-divider-container">
            <div className="date-divider-left"></div>
            <Text className="date-label">{group.dateLabel}</Text>
            <div className="date-divider-right"></div>
          </div>

          {group.records.map((chat: Chat, index: number) => {
            const sessionId = chat.resource.identifier
              .find((id: Identifier) => id.system === chatgpt_session_id)
              ?.value.replace(/"/g, '');

            const handleClick = async (): Promise<void> => {
              setIsSessionLoading(true);
              const sessionId = chat.resource.identifier
                .find((id: Identifier) => id.system === chatgpt_session_id)
                ?.value.replace(/"/g, '');
              setSelectedSessionId(sessionId || '');
              setLikeUnlike(sessionId || '');

              // Find the matched resource
              const matchedResource = converstionHistoryList.find((chat: any) =>
                chat.resource.identifier.some((id: any) => id.system === chatgpt_session_id && id.value === sessionId)
              );

              if (matchedResource) {
                const payloadData = matchedResource.resource.payload || [];
                const updatedData: any[] = [];
                //This code will be removed after the new payload structure is implemented
                // if (Array.isArray(payloadData)) {
                //   payloadData.forEach((payloadItem: any) => {
                //     if (payloadItem.contentString !== 'NA') {
                //       try {
                //         const question = payloadItem.question;
                //         const answer = payloadItem.answer;
                //         const detectedIntent = payloadItem.detectedIntent;
                //         let patientName = '';
                //         let patientId = profileId;
                //         let questionId = payloadItem.id;

                //         if (answer && isJSON(answer)) {
                //           const parsedAnswer = JSON.parse(answer);
                //           patientName = parsedAnswer.patientName;
                //           patientId = parsedAnswer.patientId;
                //           questionId = parsedAnswer.questionId;
                //         }

                //         updatedData.push({
                //           title: question,
                //           description: answer,
                //           loading: false,
                //           detectedIntent: detectedIntent,
                //           patientName: patientName,
                //           selectedPatient: patientId,
                //           questionId: questionId,
                //           sessionId: sessionId,
                //         });
                //       } catch (error) {
                //         console.error('Error parsing contentString:', error);
                //       }
                //     } else {
                //       console.log('No valid contentString to parse');
                //     }
                //   });
                // }

                if (Array.isArray(payloadData)) {
                  payloadData.forEach((payloadItem: any) => {
                    if (payloadItem.contentString !== 'NA') {
                      try {
                        let contentData: any = payloadItem; // Default to the original structure
                        let patientName = '';
                        let patientId = profileId;
                        let questionId = payloadItem.id;
                        let question = payloadItem.question;
                        let answer = payloadItem.answer;
                        let detectedIntent = payloadItem.detectedIntent;

                        // Check if `contentString` is a valid JSON string and parse it
                        if (payloadItem.contentString && isJSON(payloadItem.contentString)) {
                          contentData = JSON.parse(payloadItem.contentString);
                          question = contentData.question || question;
                          answer = contentData.answer || answer;
                          questionId = contentData.id || questionId;
                          detectedIntent = contentData.detectedIntent || detectedIntent;

                          // If `answer` is also a JSON string, parse it
                          if (answer && isJSON(answer)) {
                            const parsedAnswer = JSON.parse(answer);
                            patientName = parsedAnswer.patientName || '';
                            patientId = parsedAnswer.patientId || patientId;
                            questionId = parsedAnswer.questionId || questionId;
                          }
                        }

                        updatedData.push({
                          title: question,
                          description: answer,
                          loading: false,
                          detectedIntent: detectedIntent,
                          patientName: patientName,
                          selectedPatient: patientId,
                          questionId: questionId,
                          sessionId: sessionId,
                        });
                      } catch (error) {
                        console.error('Error parsing contentString:', error);
                      }
                    } else {
                      console.log('No valid contentString to parse');
                    }
                  });
                }

                setHistoryData(updatedData);
                setFhirBotDetails(updatedData);
                setfhirBotDetailsUpdatedUpdated(updatedData);
                setSessionId(sessionId || '');
                setIsSessionLoading(false);
              }
            };

            return (
              <div
                key={index}
                className={`chat-session-item ${selectedSessionId === sessionId ? 'selected' : ''}`}
                onClick={handleClick}
              >
                <div className="icon-wrapper">
                  <img src="../img/message-dots-circle.svg" alt="icon" />
                </div>
                <div className="text-wrapper">
                  <div className="text-header">
                    <Text className="chat-title">
                      {chat.resource.identifier
                        .find((id) => id.system === chatgpt_session_title)
                        ?.value.replace(/"/g, '')
                        .substring(0, 30)}
                    </Text>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default ChatHistory;

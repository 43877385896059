/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Button, Select, Text, TextInput } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useState } from 'react';
import { fetchPatients } from '../../utils/CustomAPI';
import { getFullName } from '../../utils/util';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useBrandSettings } from '../../BrandContext';
import { useMedplum } from '@medplum/react-hooks';
import { HumanName } from '@medplum/fhirtypes';
import { useNavigate } from 'react-router-dom';

const defaultQuery = (type: 'PatientList' | 'PractitionerList', searchTerm: string): string => `{
  ${type}(name: "${searchTerm}", _count: 1000) {
    resourceType
    id
    name {
      family
      given
    }
  }
}`;

interface AddCommunicationProps {
  close: () => void;
}

interface CommunicationFormValues {
  practitioner: string;
  status: string;
  topic?: string;
  sent: Date;
}

const AddCommunication = (props: AddCommunicationProps) => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const form = useForm<CommunicationFormValues>({
    initialValues: {
      practitioner: '',
      status: 'in-progress',
      topic: '',
      sent: new Date(),
    },
  });
  const [patientSearchTerm, setPatientSearchTerm] = useState<string>('');
  const [selectedPractitioner, setSelectedPractitioner] = useState<{ value: string; label: string }>();
  const [practitioners, setPractitioners] = useState<{ value: string; label: string }[]>([]);
  const brandDetails = useBrandSettings();

  const getPractitioner = (searchTerm: string) => {
    setPatientSearchTerm(searchTerm);
    fetchPatients(medplum, defaultQuery('PractitionerList', searchTerm))
      .then((response) => {
        console.log('Practitioners:', response.data?.PractitionerList);
        setPractitioners(
          response.data?.PractitionerList?.map((practitioner: { id: string; name: string }) => ({
            value: practitioner.id,
            label: getFullName(practitioner?.name?.[0] as HumanName),
          }))
        );
      })
      .catch((error) => {
        console.error('Error fetching patients:', error);
      });
  };

  const createNewCommunication = (values: CommunicationFormValues): void => {
    values.practitioner = selectedPractitioner?.value || '';
    const patientName = medplum?.getProfile()?.name?.[0];
    const patientId = medplum.getProfile()?.id;
    medplum
      .createResource({
        resourceType: 'Communication',
        status: values.status as 'in-progress' | 'completed' | 'on-hold',
        subject: {
          reference: `Patient/${medplum.getProfile()?.id}`,
          display: patientName ? getFullName(patientName) : 'Unknown',
        },
        sent: values.sent.toISOString(),
        topic: {
          coding: [
            {
              system: 'http://terminology.hl7.org/CodeSystem/communication-topic',
              code: values.topic,
              display: values.topic,
            },
          ],
        },
        category: [
          {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/communication-category',
                code: 'Message',
                display: 'Message',
              },
            ],
          },
        ],
        sender: {
          reference: `Patient/${medplum.getProfile()?.id}`,
          display: patientName ? getFullName(patientName) : 'Unknown',
        },
        recipient: [{ reference: `Practitioner/${values.practitioner}`, display: selectedPractitioner?.label }],
      })
      .then(() => {
        showNotification({ message: 'Communication created successfully', color: 'blue' });
        navigate(`/Communication/${patientId}/communications`);
        props.close();
      })
      .catch((error) => {
        console.error('Error creating communication:', error);
      });
  };

  return (
    <Box mt={20}>
      <form onSubmit={form.onSubmit((values) => createNewCommunication(values))}>
        <Box className="flex-container">
          <Text span w={'30%'} className="title-txt">
            Practitioner
            <Text span c={'red'}>
              {' '}
              *
            </Text>
          </Text>
          <Select
            placeholder="Select"
            className="meeting-title-input-box"
            data={practitioners}
            value={selectedPractitioner?.value}
            onChange={(value) => {
              const selected = practitioners.find((practitioner) => practitioner.value === value);
              setSelectedPractitioner(selected ?? undefined);
            }}
            onSearchChange={getPractitioner}
            searchValue={patientSearchTerm}
            rightSection={<IconChevronDown size={14} />}
            styles={(theme) => ({
              label: { marginBottom: theme.spacing.xs },
            })}
            mb="12"
            searchable
            required
          />
        </Box>
        <Box className="flex-container">
          <Text span w={'30%'} className="title-txt">
            Status
            <Text span c={'red'}>
              {' '}
              *
            </Text>
          </Text>
          <Select
            placeholder="Select"
            className="meeting-title-input-box"
            value={form.values.status}
            onChange={(value) => form.setFieldValue('status', value as string)}
            data={[
              { value: 'in-progress', label: 'In Progress' },
              { value: 'completed', label: 'Completed' },
              { value: 'on-hold', label: 'On Hold' },
            ]}
            rightSection={<IconChevronDown size={14} />}
            styles={(theme) => ({
              label: { marginBottom: theme.spacing.xs },
            })}
            mb="12"
            required
          />
        </Box>
        <Box className="flex-container">
          <Text span w={'30%'} className="title-txt">
            Topic (Optional)
          </Text>
          <TextInput
            placeholder="Enter a topic"
            className="meeting-title-input-box"
            value={form.values.topic}
            onChange={(e) => form.setFieldValue('topic', e.currentTarget.value)}
            styles={(theme) => ({
              label: { marginBottom: theme.spacing.xs },
            })}
            mb={12}
          />
        </Box>
        <Box className="flex-container">
          <Text span w={'30%'} className="title-txt">
            Sent
          </Text>
          <DateInput
            placeholder="Select a date"
            className="meeting-title-input-box"
            valueFormat="DD/MM/YYYY HH:mm:ss"
            value={form.values.sent}
            onChange={(value) => form.setFieldValue('sent', value as Date)}
            mb={12}
            readOnly
          />
        </Box>
        <Box className="telehealth-create-meeting-btn-container">
          <Button
            size="md"
            onClick={props.close}
            bg="white"
            mt={10}
            radius={4}
            variant="default"
            fullWidth
            className="telehealth-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            className="common-btn"
            radius={4}
            mt={10}
            fullWidth
            type="submit"
            style={{ backgroundColor: brandDetails?.buttonColor }}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddCommunication;

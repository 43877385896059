import React from 'react';
import { Paper, Text, Group, Avatar } from '@mantine/core';
import { Practitioner } from '@medplum/fhirtypes';
import { useBrandSettings } from '../../BrandContext';
import { useMedplum } from '@medplum/react';
interface ChatMessageProps {
  sender: 'patient' | 'provider';
  message: string;
  time: string;
  subject?: { reference: string; display: string };
  practitionerData?: Practitioner;
  providerName?: string;
}

const getInitials = (name: string) =>
  name
    ? name
        .split(' ')
        .map((word) => word[0])
        .join('')
        .toUpperCase()
    : '?';

const ChatMessage: React.FC<ChatMessageProps> = ({ sender, message, time, practitionerData, providerName }) => {
  const brandDetails = useBrandSettings();
  return (
    <Group
      mt={15}
      p="15px"
      align="flex-start"
      style={{
        justifyContent: sender === 'patient' ? 'flex-end' : 'flex-start',
        overflow: 'hidden',
        boxSizing: 'border-box',
      }}
    >
      {sender === 'provider' && (
        <Avatar radius="xl" size={50} style={{ backgroundColor: '#F9FAFB' }}>
          {getInitials(providerName || '')}
        </Avatar>
      )}

      <div
        style={{
          textAlign: sender === 'patient' ? 'right' : 'left',
          maxWidth: '75%',
          width: message.length > 50 ? '60%' : 'auto',
          wordBreak: 'break-word',
          overflowY: 'auto',
          maxHeight: '200px',
        }}
      >
        <Text
          size="xs"
          color="dimmed"
          style={{
            marginTop: '5px',
            textAlign: 'right',
            display: 'block',
          }}
        >
          {/* {sender === 'patient'
            ? 'You'
            : `${practitionerData?.name?.[0]?.given?.join(' ') ?? ''} ${practitionerData?.name?.[0]?.family ?? ''}`}
          {' • '} */}
          {sender === 'patient' ? 'You' : ` ${providerName ? `• ${providerName}` : ''}`}
          {' • '}

          {time}
        </Text>
        <Paper
          shadow="xs"
          radius="md"
          p="sm"
          mt="xs"
          ta="left"
          style={(theme) => ({
            backgroundColor: sender === 'patient' ? brandDetails?.buttonColor : '#F9FAFB',
            color: sender === 'patient' ? theme.white : theme.black,
            border: `1px solid ${sender === 'patient' ? brandDetails?.buttonColor : '#EAECF0'}`,
            overflowY: 'auto',
            maxHeight: '150px',
            boxSizing: 'border-box',
          })}
        >
          {message}
        </Paper>
      </div>
    </Group>
  );
};

export default ChatMessage;

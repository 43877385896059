import { Communication, HumanName } from '@medplum/fhirtypes';
import { BaseChat, Loading, useMedplum, useMedplumProfile, Container } from '@medplum/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePatient } from '../../hooks/usePatient';
import { createReference, formatHumanName, getReferenceString } from '@medplum/core';
import { Alert } from '@mantine/core';
import { cretaeCommunication } from '../../utils/CustomAPI';

export function CommunicationTab(): JSX.Element {
  const medplum = useMedplum();
  const profile = useMedplumProfile();
  const profileRef = useMemo(() => (profile ? createReference(profile) : undefined), [profile]);
  const patient = usePatient();
  const [communications, setCommunications] = useState<Communication[]>([]);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [providerDetails, setProviderDetails] = useState({ display: '', reference: '' });

  const handleProviderDetails = (details: { display: string; reference: string }) => {
    setProviderDetails(details);
  };
  
  const providerDetailsRef = useRef(providerDetails);

  useEffect(() => {
    providerDetailsRef.current = providerDetails;
  }, [providerDetails]);

  const sendMessage = useCallback(
    (message: string) => {
      if (!patient) {
        return;
      }

      if (!profileRef) {
        return;
      }
      const payload = {
        resourceType: 'Communication',
        status: 'in-progress',
        sender: profileRef,
        subject: profileRef,
        recipient: [providerDetailsRef.current],
        sent: new Date().toISOString(),
        payload: [{ contentString: message }],
      }
      cretaeCommunication(medplum, payload).then(() => {
        setIsRefreshing(true);
      }).catch(console.error);
    },
    [medplum, patient, profileRef]
  );

  if (!profileRef) {
    return <Alert color="red">Error: Provider profile not found</Alert>;
  }

  if (!patient) {
    return <Loading />;
  }

  return (
    <Container size="sm">
      <BaseChat
        title={`Communications with ${formatHumanName(patient.name?.[0] as HumanName)}`}
        communications={communications}
        setCommunications={setCommunications}
        query={`subject=${getReferenceString(patient)}`}
        sendMessage={sendMessage}
        isRefreshing={isRefreshing}
        onProviderDetails={handleProviderDetails}
        radius="sm"
        shadow="sm"
        h={600}
        mt="xl"
      />
    </Container>
  );
}

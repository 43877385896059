import { Group, AppShell as MantineAppShell, Menu, UnstyledButton, Text, Badge } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import cx from 'clsx';
import { useEffect, useState } from 'react';
import classes from '../../../react/src/AppShell/Header.module.css';
import { HeaderDropdown } from '../../../react/src/AppShell/HeaderDropdown';
import { HeaderDropdownNotification } from '../../../react/src/AppShell/HeaderDropdownNotification';
import { HeaderSearchInput } from '../../../react/src/AppShell/HeaderSearchInput';
import { ResourceAvatar, useMedplum, useMedplumProfile } from '@medplum/react';
import { capitalize } from '@medplum/core';
import { api_healthside } from '../utils/constantString';
import { getConfig } from '../config';
import { getNotifications } from '../utils/CustomAPI';
const config = getConfig();

export interface HeaderProps {
  readonly pathname?: string;
  readonly searchParams?: URLSearchParams;
}

export function Header(props: HeaderProps): JSX.Element {
  const profile = useMedplumProfile();
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [notificationMenuOpened, setNotificationMenuOpened] = useState(false);
  const medplum = useMedplum();
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationTotalCount, setNotificationTotalCount] = useState(0);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    fetchNotificationsOnly().catch(console.error);
  }, []);

  const fetchNotificationsOnly = async (): Promise<void> => {
    try {
      const response = await getNotifications(medplum, 20, 0);
      if (response) {
        const notifications = response.sortedNotifications;
        setNotificationTotalCount(response.total);
        setNotifications(notifications.slice(0, 4));

        // Count only active notifications
        const activeCount = notifications.filter(
          (n: { id: string; status: string }) => n.id && n.status === 'active'
        ).length;
        setNotificationCount(activeCount);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const fetchNotificationsAndUpdateStatus = async (): Promise<void> => {
    try {
      const response = await getNotifications(medplum, 20, 0);
      if (response) {
        const notifications = response.sortedNotifications;
        setNotifications(notifications.slice(0, 4));

        // Filter only active notifications
        const activeNotifications = notifications.filter(
          (n: { id: string; status: string }) => n.id && n.status === 'active'
        );

        // Update only active notifications
        await Promise.all(
          activeNotifications.map((n: { id: string }) =>
            medplum.updateResource({
              resourceType: 'CommunicationRequest',
              id: n.id,
              status: 'completed',
            })
          )
        );

        // Fetch the updated notifications and count active ones
        const updatedResponse = await getNotifications(medplum, 20, 0);
        if (updatedResponse) {
          const updatedNotifications = updatedResponse.sortedNotifications;
          const updatedActiveCount = updatedNotifications.filter(
            (n: { id: string; status: string }) => n.id && n.status === 'active'
          ).length;

          setNotificationCount(updatedActiveCount);
        }
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleNotificationClick = async () => {
    const newState = !notificationMenuOpened;
    setNotificationMenuOpened(newState);

    if (newState) {
      await fetchNotificationsAndUpdateStatus();
    }
  };

  return (
    <MantineAppShell.Header
      p={8}
      mt={10}
      style={{ border: 'none', position: 'relative', paddingLeft: '0' }}
      className="cust_header"
    >
      <Group justify="space-between">
        <Group gap={40}>
          <HeaderSearchInput pathname={props.pathname} searchParams={props.searchParams} />
        </Group>
        <Group gap="lg" pr="sm">
          {config.baseApiUrl === api_healthside && (
            <Menu
              width={400}
              shadow="xl"
              position="bottom-end"
              transitionProps={{ transition: 'pop-top-right' }}
              opened={notificationMenuOpened}
              onClose={() => setNotificationMenuOpened(false)}
              radius={8}
            >
              <Menu.Target>
                <UnstyledButton
                  className={cx(classes.user, { [classes.userActive]: notificationMenuOpened })}
                  onClick={handleNotificationClick}
                  style={{ position: 'relative' }}
                >
                  <img src={'../img/notification_icon.svg'} />

                  {notificationCount > 0 && (
                    <Badge
                      color="red"
                      size="lg"
                      className="tw-absolute tw-top-1 tw-right-1 tw-w-5 tw-h-5 tw-flex tw-items-center tw-justify-center tw-text-xs tw-font-normal tw-rounded-full tw-p-0"
                    >
                      {notificationCount}
                    </Badge>
                  )}
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                <HeaderDropdownNotification
                  notifications={notifications}
                  notificationCount={notificationCount}
                  notificationTotalCount={notificationTotalCount}
                  closeDropdown={() => setNotificationMenuOpened(false)}
                />
              </Menu.Dropdown>
            </Menu>
          )}

          <Menu
            width={260}
            shadow="xl"
            position="bottom-end"
            transitionProps={{ transition: 'pop-top-right' }}
            opened={userMenuOpened}
            onClose={() => setUserMenuOpened(false)}
          >
            <Menu.Target>
              <UnstyledButton
                className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                onClick={() => setUserMenuOpened((o) => !o)}
              >
                <Group gap={7}>
                  <ResourceAvatar value={profile} radius="xl" size={24} />
                  <Text size="sm" className={classes.userName}>
                    {capitalize(profile?.name?.[0].given?.[0] ?? '') +
                      ' ' +
                      capitalize(profile?.name?.[0].family ?? '')}
                  </Text>
                  <IconChevronDown size={12} stroke={1.5} />
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <HeaderDropdown version="" />
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
    </MantineAppShell.Header>
  );
}

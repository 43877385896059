import React, { useEffect, useState } from 'react';
import { Box, Paper, Divider, Title, Container, Center, Loader } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { getNotifications } from '../utils/CustomAPI';
import { Header } from '../components/Header';
import { Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import CommonDrawer from '../components/CommonDrawer';

interface Notification {
  title: string;
  description: string;
  eventAt: string;
}

const NotificationsPage: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const medplum = useMedplum();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(notifications.length / itemsPerPage);
  const paginatedData = notifications.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);

  //const appointmentId;

  const fetchData = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await getNotifications(medplum, 1000, 0);
      if (response) {
        setNotifications(response.sortedNotifications);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData().catch(console.error);
  }, []);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const formatDescription = (description: string): string => {
    if (!description) return '';
    const dateMatch = description.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/);
    if (dateMatch) {
      const formattedDate = new Date(dateMatch[0]).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      return description.replace(dateMatch[0], formattedDate);
    }
    return description;
  };

  const formatTitle = (title: string): string => {
    if (!title) return '';
    return title
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleClick = (notification: any): void => {
    const firstReference = notification?.references?.[0];

    if (!firstReference?.reference) {
      console.error('Reference not found');
      return;
    }

    const id = firstReference.reference.split('/')[1];

    if (notification?.type === 'Telehealth') {
      setIsDrawerOpened(true);
      setSelectedAppointment(id);
    } else {
      navigate(`/Questionnaire`, {
        state: {},
      });
    }
  };

  return (
    <>
      <Header />
      <Paper radius="12px">
        <Box style={{ width: '100%' }}>
          <Divider />
          <div style={{ marginLeft: '30px', marginTop: '15px' }}>
            <Title>Notifications</Title>
          </div>
          {loading ? (
            <Container>
              <Center style={{ height: 300 }}>
                <Loader size="lg" color="blue" variant="dots" />
              </Center>
            </Container>
          ) : (
            <div className="tw-bg-white tw-mt-3 tw-relative tw-overflow-x-auto tw-rounded-xl tw-mx-6 table-container">
              <table className="tw-w-full tw-text-sm tw-text-left listing_table">
                <thead className="tw-text-xs tw-text-gray-700 tw-bg-[#F9FAFB] tw-border-b-[1px] tw-border-[#EAECF0]">
                  <tr>
                    <th className="tw-px-4 tw-py-4 tw-px-[0.9rem] tw-text-[#475467] tw-font-medium tw-text-sm">
                      Title
                    </th>
                    <th className="tw-px-4 tw-py-4 tw-px-[0.9rem] tw-text-[#475467] tw-font-medium tw-text-sm">
                      Description
                    </th>
                    <th className="tw-px-4 tw-py-4 tw-px-[0.9rem] tw-text-[#475467] tw-font-medium tw-text-sm">
                      Date & Time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedData.length > 0 ? (
                    paginatedData.map((notification, index) => (
                      <tr
                        key={index}
                        className="tw-border-b-[1px] tw-border-[#EAECF0] tw-cursor-pointer hover:tw-bg-gray-100"
                        onClick={() => handleClick(notification)}
                      >
                        <td className="tw-px-[0.9rem] tw-py-5 tw-font-[400] tw-text-[#475467] tw-text-[14px]">
                          {formatTitle(notification.title)}
                        </td>
                        <td className="tw-px-[0.9rem] tw-py-5 tw-font-[400] tw-text-[#475467] tw-text-[14px]">
                          {formatDescription(notification.description)}
                        </td>
                        <td className="tw-px-[0.9rem] tw-py-5 tw-font-[400] tw-text-[#475467] tw-text-[14px]">
                          {new Date(notification?.eventAt).toLocaleString('en-US', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                          })}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <Container>
                          <Center style={{ height: 150 }}>
                            <Text size="xl" color="dimmed">
                              No results found
                            </Text>
                          </Center>
                        </Container>
                      </td>
                    </tr>
                  )}
                </tbody>
                {isDrawerOpened && (
                  <CommonDrawer
                    opened={isDrawerOpened}
                    onClose={() => setIsDrawerOpened(false)}
                    type={'JoinCall'}
                    appointment={selectedAppointment}
                    title="Join a Call"
                  />
                )}
              </table>
              {/* Pagination */}
              <div className="tw-flex tw-justify-between tw-items-center tw-mt-4 tw-m-3">
                <button
                  className={`tw-px-4 tw-flex tw-items-center tw-py-2 tw-text-sm tw-font-semibold tw-text-[#344054] ${
                    currentPage === 1 ? 'tw-opacity-50' : 'tw-bg-white hover:tw-bg-gray-50 tw-cursor-pointer'
                  } tw-border tw-border-[#D0D5DD] tw-rounded-lg`}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  &larr; Previous
                </button>
                <div className="tw-flex tw-items-center">
                  {[...Array(totalPages)].map((_, index) => (
                    <button
                      key={index}
                      className={`tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-lg ${
                        index + 1 === currentPage ? 'tw-bg-[#F9FAFB] tw-text-gray-700' : 'tw-text-gray-700 tw-bg-white'
                      }`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
                <button
                  className={`tw-px-4 tw-flex tw-items-center tw-py-2 tw-text-sm tw-font-semibold tw-text-[#344054] ${
                    currentPage === totalPages ? 'tw-opacity-50' : 'tw-bg-white hover:tw-bg-gray-50 tw-cursor-pointer'
                  } tw-border tw-border-[#D0D5DD] tw-rounded-lg`}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next &rarr;
                </button>
              </div>
            </div>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default NotificationsPage;

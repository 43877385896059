import { Avatar, Divider, Group, Text } from '@mantine/core';
import './Attendees.css';
import { useAppContext } from '../../AppProvider';

const Attendees = (): JSX.Element => {
  const { hostParticipants, joinUser } = useAppContext();

  return (
    <div style={{ width: '480px', overflowX: 'hidden' }}>
      <div className="host-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ position: 'relative', marginRight: '8px' }}>
            <Avatar src="/placeholder.svg?height=40&width=40" size={40} radius="xl" />
            <span className={`status-indicator ${hostParticipants.isHost ? 'online' : 'offline'}`}></span>
          </div>
          <div>
            <Text className="tw-font-medium tw-text-black tw-text-[14px]"> {hostParticipants.hostName || '-'}</Text>
            <Text className="tw-font-normal tw-text-gray-500 tw-text-[12px]">Meeting host</Text>
          </div>
        </div>
      </div>
      <Divider className="tw-border-[#EEF1F6] tw-mb-[6px]" />

      <>
        <div className="host-container">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ position: 'relative', marginRight: '8px' }}>
              <Avatar src="/placeholder.svg?height=40&width=40" size={40} radius="xl" />
              <span className="status-indicator online"></span>
            </div>
            <div>
              <Text style={{ fontWeight: 500, color: '#000', fontSize: '14px' }}> {joinUser || '-'}</Text>
              <Text style={{ fontWeight: 400, color: 'gray', fontSize: '12px' }}>{'Attendee'}</Text>
            </div>
          </div>
        </div>
        <Divider className="tw-border-[#EEF1F6]" />
      </>
    </div>
  );
};

export default Attendees;

import { ActionIcon, Autocomplete, Box, Button, Loader, Text, TextInput } from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconCircleCheck } from '@tabler/icons-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import './Telehealth.css';
import { useBrandSettings } from '../../BrandContext';
import { DateInput } from '@mantine/dates';
import Slots from '../../components/Slots';
import { Appointment, Slot } from '@medplum/fhirtypes';
import { SelectedSlot } from '../../utils/interface';
import { bookSlot, getProviderByService } from '../../utils/CustomAPI';
import { showNotification } from '@mantine/notifications';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useMedplumContext } from '@medplum/react-hooks';

interface InstantAppointmentFormProps {
  closeDialog: () => void;
}

const InstantAppointmentForm = ({ closeDialog }: InstantAppointmentFormProps): JSX.Element => {
  const { medplum } = useMedplumContext();
  const patientName = medplum.getActiveLogin()?.profile?.display;
  const patientId = medplum.getProfile()?.id;
  const brandDetails = useBrandSettings();
  const [services, setServices] = useState<any[]>([]);
  const slot: Slot | undefined = (event as any)?.resource;
  const [selectedSlot, setSelectedSlot] = useState<SelectedSlot | undefined>();
  const [selectedPractitioner, setSelectedPractitioner] = useState<{ value: string; label: string } | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const [practitioners, setPractitioners] = useState<any[]>([]);
  const navigate = useNavigate();
  const currentSlot = useRef<Slot | undefined>(slot);
  const onSelectSlot = (slot: SelectedSlot) => {
    setSelectedSlot({
      ...slot,
    });
  };

  const [formData, setFormData] = useState<{ serviceType: string; startDate: string; title: string }>({
    serviceType: '',
    startDate: '',
    title: '',
  });

  useEffect(() => {
    loadServices();
  }, []);

  async function handleSubmit(): Promise<void> {
    if (!formData.serviceType) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Please select a service',
      });
      return;
    }
    if (!selectedPractitioner) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Please select a provider',
      });
      return;
    }
    if (!formData.startDate) {
      showNotification({ color: 'red', title: 'Error', message: 'Please select Date of appointment' });
      return;
    }

    if (!selectedSlot) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Please select an appointment slot',
      });
      return;
    }
    setIsLoading(true);
    try {
      const appointment: Appointment = {
        resourceType: 'Appointment',
        status: 'booked',
        start: selectedSlot?.startTime,
        end: selectedSlot?.endTime,
        slot: [
          {
            reference: `Slot/${slot?.id ?? selectedSlot?.id}`,
          },
        ],
        description: formData.title,
        appointmentType: {
          coding: [
            {
              system: 'http://terminology.hl7.org/CodeSystem/v2-0276',
              code: 'ROUTINE',
              display: 'Routine appointment',
            },
          ],
        },
        serviceType: [
          {
            coding: [
              {
                system: 'http://hl7.org/fhir/ValueSet/service-type',
                code: 'Telehealth',
                display: 'Telehealth',
              },
            ],
          },
        ],
        participant: [
          {
            actor: {
              reference: `Patient/${patientId}`,
              display: patientName,
            },
            status: 'accepted',
          },
          {
            actor: {
              reference: `Practitioner/${selectedPractitioner?.value}`,
              display: selectedPractitioner?.label,
            },
            status: 'accepted',
          },
        ],
        comment: formData.title,
      };

      const payload = { appointment };
      await bookSlot(medplum, payload);
      setIsLoading(false);
      closeDialog();
      navigate(`/telehealth`);
      showNotification({
        icon: <IconCircleCheck />,
        title: 'Success',
        message: 'Appointment created',
      });
    } catch (error) {
      console.error('Error booking slot:', error);
    }
  }

  // load services list
  const loadServices = useCallback(async () => {
    try {
      const res: any = await medplum.search('HealthcareService');
      setServices(
        res?.entry.map((entry: any) => ({
          value: entry.resource?.id,
          label: entry?.resource?.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleDateChange = (direction: 'prev' | 'next'): void => {
    const currentDate = formData.startDate ? new Date(formData.startDate) : new Date();
    const newDate = dayjs(currentDate)
      .add(direction === 'next' ? 1 : -1, 'day')
      .toDate();
    setFormData((prev) => ({
      ...prev,
      startDate: newDate.toISOString(),
    }));
  };

  return (
    <div>
      <Box className="flex-container" mt={15}>
        <Text span w={'30%'} className="title-txt">
          Patient{' '}
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <TextInput
          placeholder="Enter a title"
          className="meeting-title-input-box"
          disabled
          value={patientName}
          styles={(theme) => ({
            label: { marginBottom: theme.spacing.xs },
          })}
          mb={12}
        />
      </Box>
      <Box className="flex-container">
        <Text span w={'30%'} className="title-txt">
          Title (Optional)
        </Text>
        <TextInput
          placeholder="Enter a title"
          className="meeting-title-input-box"
          value={formData.title}
          onChange={(e) => setFormData((prev) => ({ ...prev, title: e.currentTarget.value }))}
          styles={(theme) => ({
            label: { marginBottom: theme.spacing.xs },
          })}
          mb={12}
        />
      </Box>
      <Box className="flex-container">
        <Text span w={'30%'} className="title-txt">
          Select Service
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <Autocomplete
          placeholder="Select service"
          className="meeting-title-input-box"
          data={services}
          value={formData.serviceType}
          onChange={async (value) => {
            const selectedService = services.find((service) => service.label === value);
            setFormData((prev) => ({ ...prev, serviceType: value || '' }));
            if (selectedService) {
              try {
                const res = await getProviderByService(medplum, selectedService.value);
                if (res?.data) {
                  const uniqueProviders = new Map();
                  res.data.forEach((provider: any) => {
                    if (!uniqueProviders.has(provider.id)) {
                      uniqueProviders.set(provider.id, {
                        value: provider.id,
                        label: provider.name,
                      });
                    }
                  });

                  setPractitioners(Array.from(uniqueProviders.values()));
                }
              } catch (error) {
                console.error('Error fetching providers:', error);
              }
            }

            console.log('Practitioners (before state update):', practitioners);
          }}
          mb={12}
        />
      </Box>

      <Box className="flex-container">
        <Text span w={'30%'} className="title-txt">
          Select Practitioner
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <Autocomplete
          placeholder="Select Practitioner"
          className="meeting-title-input-box"
          data={practitioners}
          value={selectedPractitioner?.label || ''}
          onChange={async (value) => {
            const selected = practitioners.find((practitioner) => practitioner.label === value);
            setSelectedPractitioner(selected);
          }}
          mb={12}
        />
      </Box>
      {/* <Box className="flex-container">
        <PatientPractitionerSelect practitioner={true} onResourceSelect={handleResourceSelect} />
      </Box> */}
      <Box className="flex-container">
        <Text span w={'30%'} className="title-txt">
          Date of Appointment{' '}
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <DateInput
          leftSection={
            <Button bg={'#e0f5f5'} p={0} h={'1.7rem'} w={'1.7rem'} title="Previous Day">
              <ActionIcon variant="transparent" onClick={() => handleDateChange('prev')}>
                <IconChevronLeft size={15} stroke={2.2} color={brandDetails?.buttonColor} />
              </ActionIcon>
            </Button>
          }
          placeholder="Date of appointment"
          className="meeting-title-input-box"
          mb={12}
          value={formData.startDate ? new Date(formData.startDate) : undefined}
          defaultValue={slot?.start ? new Date(slot.start) : undefined}
          onChange={(value) =>
            setFormData((prev) => ({
              ...prev,
              startDate: value ? value.toISOString() : '',
            }))
          }
          minDate={new Date()}
          rightSection={
            <Button bg={'#e0f5f5'} p={0} h={'1.7rem'} w={'1.7rem'} title="Next Day">
              <ActionIcon variant="transparent" onClick={() => handleDateChange('next')}>
                <IconChevronRight size={15} stroke={2.2} color={brandDetails?.buttonColor} />
              </ActionIcon>
            </Button>
          }
          styles={{
            input: {
              textAlign: 'center', // Centers the placeholder and input text
            },
          }}
        />
      </Box>
      <Slots
        onSelectSlot={onSelectSlot}
        startDate={slot?.start ?? formData.startDate}
        slotError={false}
        currentSlotId={currentSlot?.current?.id}
      />
      <Box className="telehealth-create-meeting-btn-container">
        <Button
          size="md"
          onClick={closeDialog}
          bg="white"
          mt={10}
          radius={4}
          variant="default"
          fullWidth
          className="telehealth-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          className="telehealth-start-vedio-btn"
          style={{ backgroundColor: brandDetails?.buttonColor }}
          onClick={() => {
            handleSubmit();
          }}
          radius={4}
          mt={10}
          fullWidth
        >
          Book Appointment{isLoading && <Loader size={20} ml={10} color="#fff" />}
        </Button>
      </Box>
    </div>
  );
};

export default InstantAppointmentForm;

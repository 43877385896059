import { Appointment } from '@medplum/fhirtypes';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { STATUS_COLORS } from '../../utils/constant';
import { Badge, Center, Loader, Text, Tooltip } from '@mantine/core';
import { Container } from '@medplum/react';
import { IconTrash, IconVideo } from '@tabler/icons-react';
import CommonDrawer from '../../components/CommonDrawer';

interface TelehealthTableProps {
  appointmentsResource: Appointment[];
}

const TelehealthTable: React.FC<TelehealthTableProps> = ({ appointmentsResource }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const paginatedData = appointmentsResource.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(appointmentsResource.length / itemsPerPage);
  const [isAppointment, setIsAppointment] = useState<boolean>(false);
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [hoveredAppointmentId, setHoveredAppointmentId] = useState<string | null>(null);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000); // Simulate loading delay for data
  }, [appointmentsResource]);

  return (
    <div className="tw-bg-white tw-mt-3 tw-relative tw-overflow-x-auto tw-rounded-xl tw-mx-6 table-container">
      {loading ? (
        <Container>
          <Center style={{ height: 300 }}>
            <Loader size="lg" color="blue" variant="dots" />
          </Center>
        </Container>
      ) : (
        <>
          <table className="tw-w-full tw-text-sm tw-text-left listing_table">
            <thead className="tw-text-xs tw-text-gray-700 tw-bg-[#F9FAFB] tw-border-b-[1px] tw-border-[#EAECF0]">
              <tr>
                <th className="tw-px-4 tw-py-4 tw-px-[0.9rem] tw-text-[#475467] tw-font-medium tw-text-sm">
                  Provider Name
                </th>
                <th className="tw-px-4 tw-py-4 tw-px-[0.9rem] tw-text-[#475467] tw-font-medium tw-text-sm">
                  Appointment Date & Time
                </th>
                <th className="tw-px-4 tw-py-4 tw-px-[0.9rem] tw-text-[#475467] tw-font-medium tw-text-sm">Duration</th>
                <th className="tw-px-4 tw-py-4 tw-px-[0.9rem] tw-text-[#475467] tw-font-medium tw-text-sm">Status</th>
                <th className="tw-px-4 tw-py-4 action tw-px-[0.9rem] tw-text-[#475467] tw-font-medium tw-text-sm">
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {paginatedData?.length > 0 ? (
                paginatedData.map((item: any) => {
                  const appointment = item.resource as Appointment;
                  const providerName =
                    appointment.participant
                      ?.filter((participant: any) => participant.actor?.reference?.match(/Parctitioner|Practitioner/))
                      .map((participant: any) => participant.actor?.display)
                      .join(', ') || '-';

                  const isAppointmentFulfilled: boolean = appointment.status === 'fulfilled';
                  const appointmentStart = appointment.start ? new Date(appointment.start) : null;
                  const currentTime = new Date();
                  const isWithin15Minutes = appointmentStart
                    ? appointmentStart.getTime() - currentTime.getTime() <= 15 * 60 * 1000
                    : false;

                  const appointmentDateOnly = appointmentStart
                    ? new Date(appointmentStart.getFullYear(), appointmentStart.getMonth(), appointmentStart.getDate())
                    : null;
                  const currentDateOnly = new Date(
                    currentTime.getFullYear(),
                    currentTime.getMonth(),
                    currentTime.getDate()
                  );

                  const isPastDate = appointmentDateOnly && appointmentDateOnly < currentDateOnly;

                  return (
                    <tr className="tw-border-b-[1px] tw-border-[#EAECF0]" key={appointment.id}>
                      <td className="tw-px-[0.9rem] tw-py-5 tw-font-[400] tw-text-[#475467] tw-text-[14px]">
                        {providerName || '-'}
                      </td>
                      <td className="tw-px-[0.9rem] tw-py-5 tw-font-normal tw-text-[#475467] tw-text-[14px]">
                        {appointment?.start
                          ? `${new Date(appointment?.start).getDate()} ${new Date(appointment?.start).toLocaleString(
                              'en-US',
                              {
                                month: 'short',
                                year: 'numeric',
                              }
                            )} ${new Date(appointment?.start).toLocaleTimeString('en-US', {
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                              hour12: true,
                            })}`
                          : '-'}
                      </td>
                      <td className="tw-px-[0.9rem] tw-py-5 tw-font-normal tw-text-[#475467]">
                        {appointment?.minutesDuration ? `${appointment?.minutesDuration} min` : '-'}
                      </td>
                      <td className="tw-px-[0.9rem] tw-py-5 tw-font-normal tw-text-[#475467]">
                        <Badge
                          variant="light"
                          color={STATUS_COLORS[appointment.status]}
                          className="!tw-bg-white !tw-text-[#344054] !tw-flex !tw-items-center !tw-text-xs !tw-border !tw-border-[#D0D5DD] !tw-rounded-md !tw-capitalize !tw-font-medium !tw-py-3 !tw-px-1.5"
                        >
                          <span
                            className="tw-inline-block tw-w-1.5 tw-h-1.5 tw-rounded-full tw-mr-1.5"
                            style={{ backgroundColor: STATUS_COLORS[appointment.status] }}
                          ></span>
                          {appointment.status === 'booked'
                            ? 'New'
                            : appointment.status === 'fulfilled'
                              ? 'Completed'
                              : appointment.status || '-'}
                        </Badge>
                      </td>
                      <td className="tw-px-6 tw-py-4">
                        <div className="tw-flex tw-gap-4">
                          {/* <IconTrash
                            size={22}
                            stroke="1.67px"
                            className="tw-text-[#475467] hover:tw-text-red-500 tw-cursor-pointer"
                            onClick={() => {}}
                          /> */}

                          <Tooltip
                            label={
                              isPastDate && !isAppointmentFulfilled
                                ? 'Call Expired'
                                : isAppointmentFulfilled
                                  ? 'Call Ended'
                                  : isWithin15Minutes
                                    ? 'Join Call'
                                    : 'Call not available yet'
                            }
                          >
                            <IconVideo
                              color={
                                hoveredAppointmentId === appointment.id
                                  ? '#007BFF'
                                  : isPastDate || isAppointmentFulfilled
                                    ? '#D0D5DD'
                                    : isWithin15Minutes
                                      ? '#00B14A'
                                      : '#D0D5DD'
                              }
                              stroke={1.5}
                              size={20}
                              cursor={
                                isPastDate || isAppointmentFulfilled || !isWithin15Minutes ? 'default' : 'pointer'
                              }
                              onMouseEnter={() => appointment.id && setHoveredAppointmentId(appointment.id)}
                              onMouseLeave={() => setHoveredAppointmentId(null)}
                              onClick={() => {
                                if (!isPastDate && !isAppointmentFulfilled && isWithin15Minutes) {
                                  setSelectedAppointment(appointment);
                                  setIsAppointment(true);
                                }
                              }}
                            />
                          </Tooltip>
                        </div>

                        {isAppointment && !isAppointmentFulfilled && selectedAppointment && (
                          <CommonDrawer
                            opened={isAppointment}
                            onClose={() => setIsAppointment(false)}
                            type={'JoinCall'}
                            appointment={selectedAppointment}
                            title="Join a Call"
                          />
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <Container>
                      <Center style={{ height: 150 }}>
                        <Text size="xl" color="dimmed">
                          No results found
                        </Text>
                      </Center>
                    </Container>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* Pagination */}
          <div className="tw-flex tw-justify-between tw-items-center tw-mt-4 tw-m-3">
            <button
              className={`tw-px-4 tw-flex tw-items-center tw-py-2 tw-text-sm tw-font-semibold tw-text-[#344054] ${
                currentPage === 1 ? 'tw-opacity-50' : 'tw-bg-white hover:tw-bg-gray-50 tw-cursor-pointer'
              } tw-border tw-border-[#D0D5DD] tw-rounded-lg`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &larr; Previous
            </button>
            <div className="tw-flex tw-items-center">
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  className={`tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-rounded-lg ${
                    index + 1 === currentPage ? 'tw-bg-[#F9FAFB] tw-text-gray-700' : 'tw-text-gray-700 tw-bg-white'
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
            <button
              className={`tw-px-4 tw-flex tw-items-center tw-py-2 tw-text-sm tw-font-semibold tw-text-[#344054] ${
                currentPage === totalPages ? 'tw-opacity-50' : 'tw-bg-white hover:tw-bg-gray-50 tw-cursor-pointer'
              } tw-border tw-border-[#D0D5DD] tw-rounded-lg`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next &rarr;
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TelehealthTable;

import { Box, Button, Divider, Group, Paper, Tabs, Title } from '@mantine/core';
import { Appointment } from '@medplum/fhirtypes';
import React, { useCallback, useEffect, useState } from 'react';
import { Header } from '../../components/Header';
import TelehealthTable from './TelehealthTable';
import { getAppointment } from '../../utils/CustomAPI';
import TelehealthAlert from './TelehealthAlert';
import { IconVideo } from '@tabler/icons-react';
import { useBrandSettings } from '../../BrandContext';
import { useNavigate } from 'react-router-dom';
import CommonDrawer from '../../components/CommonDrawer';
import { useMedplum } from '@medplum/react-hooks';

const filterTabs = [
  { label: 'All', value: 'all' },
  { label: 'Upcoming', value: 'pending' },
  { label: 'Completed', value: 'fulfilled' },
  { label: 'Cancelled', value: 'cancelled' },
];

const TeleHealth: React.FC = () => {
  const medplum = useMedplum();
  const [activeTab, setActiveTab] = useState('all');
  const [appointmentsResource, setAppointmentsResource] = useState<Appointment[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const brandDetails = useBrandSettings();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const handleTabChange = (newTab: string | null): void => {
    if (newTab) {
      setActiveTab(newTab);
    }
  };

  const getAppointments = useCallback(() => {
    getAppointment(medplum, medplum.getProfile()?.id || '')
      .then((res) => {
        const filteredAppointments = res.data?.entry.filter((item: any) => {
          const appointment = item.resource as Appointment;
          const providerName =
            appointment.participant
              ?.filter(
                (participant: any) =>
                  participant.actor?.reference?.includes('Practitioner') ||
                  participant.actor?.reference?.includes('Parctitioner')
              )
              .map((participant: any) => participant.actor?.display)
              .join(', ') || '-';

          // Filter by tab status
          const statusMatch =
            activeTab === 'all' ||
            (activeTab === 'pending' && appointment?.status === 'pending') ||
            (activeTab === 'fulfilled' && appointment?.status === 'fulfilled') ||
            (activeTab === 'cancelled' && appointment?.status === 'cancelled');

          // Filter by search query
          const searchMatch =
            !searchQuery || (providerName && providerName.toLowerCase().includes(searchQuery.toLowerCase()));

          // Return true only if both status and search match
          return statusMatch && searchMatch;
        });
        setAppointmentsResource(filteredAppointments || []);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [medplum, activeTab, searchQuery]);

  useEffect(() => {
    getAppointments();
  }, [getAppointments]);

  const handleStartCall = (appointment: any): void => {
    if (!appointment) {
      console.error('Appointment ID not found');
      return;
    }
    // handleRefresh();
    setIsModalOpen(false);
    setIsDrawerOpened(false);
    navigate(`/telehealth/meeting`, {
      state: {
        appointmentData: appointment,
      },
    });
  };

  const handleAddNew = (): void => {
    setIsModalOpen(true);
    setIsDrawerOpened(true);
  };

  return (
    <>
      <Header />
      <TelehealthAlert handleStartCall={handleStartCall} />
      <Paper radius="12px">
        <Box style={{ width: '100%' }}>
          <Divider />
          <Box style={{ backgroundColor: '#F9FDFD' }}>
            <Box
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                paddingTop: '15px',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ marginLeft: '30px' }}>
                <Title>Telehealth</Title>
              </div>
              <Button
                className="tw-mr-5 tw-rounded-lg"
                style={{ backgroundColor: brandDetails?.buttonColor }}
                onClick={handleAddNew}
              >
                <IconVideo size={20} style={{ marginRight: '4px' }} />
                New
              </Button>
            </Box>

            <Group justify="space-between" className="tw-mt-6 tw-mx-8 tw-pb-5">
              <div className="flex-1 relative">
                <input
                  type="text"
                  placeholder="Search Appointment"
                  className="tw-border tw-border-gray-300 tw-px-3 tw-py-2 tw-rounded-md tw-w-80 focus:tw-border-gray-500 focus:tw-outline-none"
                  value={searchQuery}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSearchQuery(value);

                    if (value === '') {
                      setActiveTab('all');
                    }
                  }}
                />
              </div>

              <div className="flex-1">
                <Tabs variant="pills" value={activeTab} onChange={handleTabChange}>
                  <Tabs.List
                    className="flex mt-0 ml-2 p-0 border border-gray-300 rounded-sm"
                    style={{
                      gap: '0',
                      display: 'flex',
                      flexWrap: 'nowrap',
                    }}
                  >
                    {filterTabs.map((tab, index) => (
                      <React.Fragment key={tab.value}>
                        <Tabs.Tab
                          value={tab.value}
                          style={{
                            backgroundColor: activeTab === tab.value ? '#F9FAFB' : 'transparent',
                            border: '1px solid #E5E7EB',
                            borderTop: '1px solid #E5E7EB',
                            padding: '8px 12px',
                            borderRadius:
                              index === 0 ? '5px 0 0 5px' : index === filterTabs.length - 1 ? '0 5px 5px 0' : '0',
                            color: activeTab === tab.value ? '#182230' : '#475467',
                            fontSize: '14px',
                            fontWeight: 500,
                          }}
                        >
                          {tab.label}
                        </Tabs.Tab>
                      </React.Fragment>
                    ))}
                  </Tabs.List>
                </Tabs>
              </div>
            </Group>
          </Box>
          <Divider />
          <Group justify="space-between" className="tw-mt-3 tw-mx-8">
            <div className="flex-1">
              <Title className="tw-text-[18px] tw-font-semibold tw-font-inter tw-text-[#101828]">
                Appointments ({appointmentsResource.length})
              </Title>
            </div>
          </Group>
          <TelehealthTable appointmentsResource={appointmentsResource} />
        </Box>
        {isDrawerOpened && (
          <CommonDrawer
            opened={isDrawerOpened}
            onClose={() => setIsDrawerOpened(false)}
            type={'Telehealth'}
            title="Create a Telehealth Meeting"
            subTitle="Enter details to start a meeting with provider"
          />
        )}
      </Paper>
    </>
  );
};

export default TeleHealth;

/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Button, Grid, Group, Loader, Stepper, Text, TextInput, Title } from '@mantine/core';
import { IconMessageCircleQuestion, IconMicrophone } from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';
import { useBrandSettings } from '../../BrandContext';
import { Patient, Questionnaire } from '@medplum/fhirtypes';
import { createQuestionnaireResponse } from '../../utils/buildFhirResourcesUtil';
import { useMedplum } from '@medplum/react';
import { showNotification } from '@mantine/notifications';
import { generatePatientHealthSummary, submitQuestionnaireResponse } from '../../utils/CustomAPI';
import { formatPatientName } from '../../utils/util';
import { useNavigate } from 'react-router-dom';

interface QuestionnaireFormProps {
  questionnaire: Questionnaire | undefined;
  close: () => void;
}

interface answerProps {
  linkId: string;
  text: string;
  answer: any;
}

const QuestionnaireForm = (props: QuestionnaireFormProps) => {
  const medplum = useMedplum();
  const brandDetails = useBrandSettings();
  const [activeStep, setActiveStep] = useState(0);
  const [responses, setResponses] = useState<{ [key: string]: string }>({});
  const [visibleRange, setVisibleRange] = useState([0, 9]);
  const { name, item } = props?.questionnaire || {};
  const totalSteps = item?.length || 0;
  const [answer, setAnswer] = useState<answerProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [listening, setListening] = useState(false);
  const navigate = useNavigate();

  const capitalizeFirstLetter = (text: string): string => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const recognitionRef = useRef<SpeechRecognition | null>(null);

  const mediaStreamRef = useRef<MediaStream | null>(null);

  const listeningRef = useRef(listening);
  useEffect(() => {
    listeningRef.current = listening;
  }, [listening]);

  useEffect(() => {
    if (recognitionRef.current) {
      recognitionRef.current.onresult = (event: SpeechRecognitionEvent) => {
        setResponses((prevResponses) => {
          const currentResponse = prevResponses[item?.[activeStep]?.linkId || '']?.trim() || '';
          let newTranscript = currentResponse;

          for (let i = event.resultIndex; i < event.results.length; i++) {
            const transcript = event.results[i][0].transcript.trim();
            newTranscript += (newTranscript ? ' ' : '') + transcript;
          }

          const updatedResponse = newTranscript.replace(/\s+/g, ' ').trim();

          if (item?.[activeStep]) {
            handleInputChange(item[activeStep].linkId || '', item[activeStep].text || '', updatedResponse);
          }

          return { ...prevResponses, [item?.[activeStep]?.linkId || '']: updatedResponse };
        });
      };
    }
  }, [activeStep, item]);

  const initializeSpeechRecognition = async () => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      showNotification({
        message: 'Speech recognition is not supported in your browser. Please try Chrome or Edge.',
        color: 'red',
      });
      return;
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = false;
      recognitionRef.current.lang = 'en-US';
      recognitionRef.current.onresult = (event: SpeechRecognitionEvent) => {
        setResponses((prevResponses) => {
          const currentResponse = prevResponses[item?.[activeStep]?.linkId || '']?.trim() || '';
          let newTranscript = currentResponse;

          for (let i = event.resultIndex; i < event.results.length; i++) {
            const transcript = event.results[i][0].transcript.trim();
            newTranscript += (newTranscript ? ' ' : '') + transcript;
          }

          const updatedResponse = newTranscript.replace(/\s+/g, ' ').trim();

          if (item?.[activeStep]) {
            handleInputChange(item[activeStep].linkId || '', item[activeStep].text || '', updatedResponse);
          }

          return { ...prevResponses, [item?.[activeStep]?.linkId || '']: updatedResponse };
        });
      };

      recognitionRef.current.onerror = (event: SpeechRecognitionErrorEvent) => {
        console.error('Speech Recognition Error:', event.error);
        if (event.error !== 'no-speech') {
          setListening(false);
          showNotification({
            message: `Speech recognition error: ${event.error}. Please try again.`,
            color: 'red',
          });
        }
      };

      recognitionRef.current.onend = () => {
        if (listeningRef.current) {
          recognitionRef.current?.start();
        }
      };

      recognitionRef.current.onstart = () => {
        setListening(true);
      };
    } catch (error) {
      console.error('Error initializing microphone:', error);
      showNotification({
        message: 'Please allow microphone access to use voice input',
        color: 'red',
      });
    }
  };

  // Cleanup function to stop recognition and media stream.
  const cleanupAudio = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      recognitionRef.current = null;
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }
    setListening(false);
  };

  const handleVoiceInput = async () => {
    if (!recognitionRef.current) {
      await initializeSpeechRecognition();
    }
    if (!recognitionRef.current) {
      return;
    }
    if (listening) {
      recognitionRef.current.stop();
      setListening(false);
      listeningRef.current = false;

      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
        mediaStreamRef.current = null;
      }
    } else {
      try {
        if (!mediaStreamRef.current) {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          mediaStreamRef.current = stream;
        }

        setResponses((prevResponses) => ({
          ...prevResponses,
          [item?.[activeStep]?.linkId || '']: '',
        }));

        recognitionRef.current.start();
        setListening(true);
        listeningRef.current = true;
      } catch (error) {
        console.error('Failed to start speech recognition:', error);
        showNotification({
          message: 'Failed to start recording. Please try again.',
          color: 'red',
        });
        setListening(false);
      }
    }
  };
  // Handle input change for text inputs
  const handleInputChange = (linkId: string, text: string, value: string | boolean) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [linkId]: typeof value === 'boolean' ? value.toString() : capitalizeFirstLetter(value),
    }));

    setAnswer((prev) => {
      const newAnswer = {
        linkId: linkId,
        text: text,
        answer: typeof value === 'boolean' ? [{ valueBoolean: value }] : [{ valueString: value }],
      };

      const existingIndex = prev.findIndex((item) => item.linkId === linkId);
      if (existingIndex !== -1) {
        // Update existing response
        const updatedAnswers = [...prev];
        updatedAnswers[existingIndex] = newAnswer;
        return updatedAnswers;
      } else {
        // Add new response
        return [...prev, newAnswer];
      }
    });
  };

  // Handle next button click event
  const handleNext = () => {
    const currentQuestion = item?.[activeStep];
    if (listening && recognitionRef.current) {
      recognitionRef.current.stop();
      setListening(false);
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
        mediaStreamRef.current = null;
      }
    }
    if (currentQuestion?.required && !responses[currentQuestion.linkId]) {
      //alert("This question is required.");
      showNotification({ message: 'Please provide the appropriate answer!', color: 'red' });
      return;
    }

    if (activeStep < totalSteps - 1) {
      const nextStep = activeStep + 1;
      setActiveStep(nextStep);

      // Update visible range
      if (nextStep > visibleRange[1]) {
        setVisibleRange([visibleRange[0] + 1, visibleRange[1] + 1]);
      }
    }
  };

  // Handle previous button click event
  const handlePrev = () => {
    if (listening && recognitionRef.current) {
      recognitionRef.current.stop();
      setListening(false);
    }
    if (activeStep > 0) {
      const prevStep = activeStep - 1;
      setActiveStep(prevStep);

      // Update visible range
      if (prevStep < visibleRange[0]) {
        setVisibleRange([visibleRange[0] - 1, visibleRange[1] - 1]);
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const patient = medplum.getProfile() as Patient; // Cast the profile to Patient type
      const patientName = formatPatientName(patient);

      // Create QuestionnaireResponse payload
      const questionnaireResponse = createQuestionnaireResponse(
        answer,
        props.questionnaire?.id || '',
        patient?.id || '',
        patientName
      );

      // Call submitQuestionnaireResponse API
      await submitQuestionnaireResponse(medplum, { assessmentResponse: questionnaireResponse });

      // Clean up audio resources before closing.
      cleanupAudio();

      // Perform subsequent actions after successful submission
      getPatientHealthSummary(true);
      updateQuestionnaire();
      props.close();
      navigate(`/Questionnaire`);
    } catch (error) {
      // Handle errors during the API call
      console.error('Error submitting questionnaire response:', error);
      showNotification({ message: 'Error submitting questionnaire response', color: 'red' });
    } finally {
      setLoading(false); // Ensure loading is disabled regardless of success or failure
    }
  };

  const getPatientHealthSummary = async (refreshSummary: boolean = true) => {
    try {
      const response = await generatePatientHealthSummary(medplum, medplum.getProfile()?.id || '', refreshSummary);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const updateQuestionnaire = () => {
    if (props.questionnaire) {
      props.questionnaire.status = 'retired';
      medplum
        .updateResource(props.questionnaire)
        .then(() => {
          setLoading(false);
          showNotification({
            message:
              'Your assessment has been successfully submitted. Check the Recent AI Surveys section on the dashboard to view the generated AI insights.',
            color: 'blue',
          });
          props.close();
        })
        .catch(() => {
          setLoading(false);
          showNotification({ message: 'Error submitting questionnaire response', color: 'red' });
          props.close();
        });
    }
  };

  useEffect(() => {
    return () => {
      cleanupAudio();
    };
  }, []);

  return (
    <div>
      <Grid mt="lg" align="center">
        <Grid.Col span={4.5}>
          <div className="tw-flex tw-gap-3 tw-items-center">
            <div
              className="tw-bg-[#E9D7FE] tw-p-2 tw-rounded-full"
              style={{ background: brandDetails?.secondaryColor }}
            >
              <IconMessageCircleQuestion color={brandDetails?.iconColor || '#9552E8'} />
            </div>
            <Text className="tw-font-bold tw-text-base" style={{ color: brandDetails?.textColor }}>
              Questions
            </Text>
            <div className="tw-border tw-border-[#D0D5DD] tw-rounded-md tw-px-2 tw-py-1 tw-text-xs tw-text-[#344054] tw-font-medium">
              Total {totalSteps}
            </div>
          </div>
        </Grid.Col>
        <Grid.Col span={7.5} className="stepper_container">
          <Stepper active={activeStep} iconSize={30} color={brandDetails?.buttonColor || '#9552E8'} mb="lg" mt="lg">
            {item?.map((_, index) => {
              if (index >= visibleRange[0] && index <= visibleRange[1]) {
                return <Stepper.Step key={index} />;
              }
              return null; // Hide steps outside the visible range
            })}
          </Stepper>
        </Grid.Col>
      </Grid>

      <Title order={6} mb="lg">
        {`Assessment : ${name}`}
      </Title>
      {item ? (
        <div className="tw-border tw-border-[#D0D5DD] tw-p-4 tw-rounded-lg">
          <div className="tw-mb-3 tw-flex tw-gap-4 tw-items-center">
            <span className="tw-relative">
              <span
                className="tw-flex tw-items-center tw-place-content-center tw-rounded-full tw-text-[#fff] tw-w-8 tw-h-8"
                style={{ backgroundColor: brandDetails?.textColor || '#000' }}
              >
                {activeStep + 1}
              </span>
            </span>
            <span>{item?.[activeStep].text}</span>
          </div>
          <div className="tw-flex tw-gap-2 tw-items-center">
            <TextInput
              placeholder=" Write / Record your answer "
              variant="default"
              className="tw-flex-1 tw-h-[50px] tw-text-lg"
              value={capitalizeFirstLetter(responses[item?.[activeStep]?.linkId || ''] || '')}
              onChange={(e) =>
                handleInputChange(item?.[activeStep].linkId || '', item?.[activeStep].text || '', e.target.value)
              }
              rightSection={
                <div className="tw-flex tw-items-center tw-absolute tw-right-2 tw-top-1/2 tw-transform tw--translate-y-1/2">
                  {listening ? (
                    <div className="tw-flex tw-items-center tw-gap-2">
                      <div
                        className="tw-w-5 tw-h-5 tw-bg-[#9552E8] tw-rounded-md tw-cursor-pointer"
                        style={{ backgroundColor: brandDetails?.buttonColor }}
                        onClick={handleVoiceInput}
                      />
                      <div className="tw-w-4 tw-h-4 tw-border-2 tw-border-black tw-border-t-transparent tw-rounded-full tw-animate-spin"></div>
                    </div>
                  ) : (
                    <IconMicrophone
                      size={22}
                      style={{ color: brandDetails?.buttonColor }}
                      onClick={handleVoiceInput}
                      className="tw-cursor-pointer"
                    />
                  )}
                </div>
              }
            />
          </div>
        </div>
      ) : (
        <Text ta="center" size="lg" mt={20}>
          {' '}
          No Assessment Questions
        </Text>
      )}
      <div
        className="footer-actions tw-border-t tw-border-gray-300 tw-bg-white tw-p-4"
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Group justify="flex-end">
          <Button
            variant="outline"
            className="tw-rounded-lg"
            color="gray"
            onClick={handlePrev}
            disabled={activeStep === 0}
          >
            Previous
          </Button>
          {activeStep === totalSteps - 1 ? (
            <Button
              color={brandDetails?.buttonColor}
              className="tw-rounded-lg"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading && <Loader size="sm" color="white" mr={10} />} Submit
            </Button>
          ) : (
            <Button color={brandDetails?.buttonColor} className="tw-rounded-lg" onClick={handleNext}>
              Next
            </Button>
          )}
        </Group>
      </div>
    </div>
  );
};

export default QuestionnaireForm;

import React, { useState } from 'react';
import { Box, Group, Tabs } from '@mantine/core';
import CustomTabs from './CustomTabs';
import Attendees from './Attendees';

interface PatientDetailsProps {}

const PatientDetails: React.FC<PatientDetailsProps> = ({}: PatientDetailsProps) => {
  const [activeTab, setActiveTab] = useState<string>('Attendees');

  return (
    <Group style={{ width: '480px', overflowX: 'hidden' }}>
      <Tabs color="#7F56D9" value={activeTab} defaultValue="Transcription" style={{ width: '100%' }}>
        <Box display="flex" style={{ width: '100%', overflowX: 'hidden' }}>
          <Tabs.List style={{ width: '100%', minWidth: 0 }}>
            <CustomTabs
              value="Attendees"
              onClick={() => setActiveTab('Attendees')}
              title={'Session Ended'}
              disabled={false}
            />
          </Tabs.List>
        </Box>
        <Tabs.Panel value="Attendees" pt="xs">
          <Attendees />
        </Tabs.Panel>
      </Tabs>
    </Group>
  );
};

export default PatientDetails;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Drawer, Text } from '@mantine/core';
import AddCommunication from '../dashboard/Messages/AddCommunication';
import InstantAppointmentForm from '../dashboard/Telehealth/InstantAppointmentForm';
import JoinCall from '../dashboard/Telehealth/JoinCall';

interface CommonDrawerProps {
  opened: boolean;
  onClose: () => void;
  type: string;
  title?: string;
  subTitle?: string;
  appointment?: any;
}

const CommonDrawer: React.FC<CommonDrawerProps> = ({ opened, onClose, type, title, subTitle, appointment }) => {
  const drawerProps = type === 'JoinCall' ? { zIndex: 1000 } : {};

  const renderContent = () => {
    switch (type) {
      case 'Communication':
        return <AddCommunication close={onClose} />;
      case 'Telehealth':
        return <InstantAppointmentForm closeDialog={onClose} />;
      case 'JoinCall':
        return <JoinCall opened={true} close={onClose} appointment={appointment} />;
      default:
        return <div>Default Content</div>;
    }
  };

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      title={
        <Box className="header">
          <Text className="header-txt">{title}</Text>
          <Text className="sub-header-txt">{subTitle}</Text>
        </Box>
      }
      offset={1}
      radius="md"
      position="right"
      className="drawer"
      size="40%"
      classNames={{ overlay: 'custom-overlay' }}
      {...drawerProps}
    >
      {renderContent()}
    </Drawer>
  );
};

export default CommonDrawer;

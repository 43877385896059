/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Button, Skeleton, Select, Divider } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import NextGenLogo from '../../static/img/logos/nextgen-logo.svg';
import EpicLogo from '../../static/img/logos/epic-logo.svg';
import AthenaLogo from '../../static/img/logos/athena-logo.svg';
import CernerLogo from '../../static/img/logos/cerner-logo.svg';
import { getOrganization, getOrganizationByEhr } from '../utils/CustomAPI';
import { useMedplum } from '@medplum/react';
import CryptoJS from 'crypto-js';
import { useBrandSettings } from '../BrandContext';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { MywellnessOrgBaseUrl, pragmaconnectCernerOrgsBaseUrl, PragmaconnectOrgBaseUrl } from '../utils/constant';

interface DropDownValueModel {
  clientId: string;
  address: string;
  authorizationUrl: string;
  tokenUrl: string;
}

interface EHRValues {
  ehr: string;
  key: string;
  name: string;
  id: string;
}

const Constants = {
  clientId: 'client_id',
  response_type: 'response_type',
  redirectURI: 'redirectURI',
  scope: 'scope',
  state: 'state',
  epicauthmobile: 'epicauthmobile',
  patient: 'patient',
  EPIC: 'EPIC',
  CERNER: 'CERNER',
  NEXTGEN: 'NEXTGEN',
  ATHENAHEALTH: 'ATHENAHEALTH',
};

const Urls = {
  oauth2_authorize: '/oauth2/authorize',
  secretKey: '5ad1ec094705b14eb05ac7c61540ee89', // Replace with your actual secret key
};

const initialOrganization = {
  name: '',
  address: '',
  clientId: '',
  tokenUrl: '',
  authorizationUrl: '',
};

const patientSSOEhrs = ['Cerner'];

const LoginWithSSO = () => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const brandDetails = useBrandSettings();
  const [ehr, setEhr] = useState<EHRValues>();
  const [ehrList, setEhrList] = useState<EHRValues[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkOrganization, setCheckOrganization] = useState<EHRValues>();
  const [getOrganizationList, setGetOrganizationList] = useState<any>();
  const [selectedOrganization, setSelectedOrganization] = useState(initialOrganization);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (brandDetails?.brandName) {
      getOrganizationByName(brandDetails?.brandName ?? '');
    }
    getOrganizationByEhrType(patientSSOEhrs, Constants.patient);
  }, [brandDetails]);

  useEffect(() => {
    if (brandDetails && Object.keys(brandDetails).length > 0) {
      setLoading(false);
    }
  }, [brandDetails]);

  const getOrganizationByName = async (name: string) => {
    setIsLoading(true);
    getOrganization(medplum, name)
      .then((response) => {
        setEhrList((prevEhr) => [...(prevEhr || []), response]);
        handleClickBtn(response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const getOrganizationByEhrType = async (ehrs: string[], integrationType: string) => {
    setIsLoading(true);
    getOrganizationByEhr(medplum, ehrs, integrationType)
      .then((response) => {
        if (Array.isArray(response)) {
          setEhrList((prevEhr) => [...(prevEhr || []), ...response]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  const handleClickBtn = async (ehr: EHRValues) => {
    setCheckOrganization(ehr);
    setSelectedOrganization(initialOrganization);
    setEhr(ehr);
    getOrganizations(ehr?.ehr);
  };

  const getOrganizations = async (ehr: string) => {
    try {
      const baseUrl = location.origin;
      let url;
      if (ehr.toUpperCase() === Constants.EPIC) {
        url = baseUrl === 'https://myhealth.mywellnessid.com' ? MywellnessOrgBaseUrl : PragmaconnectOrgBaseUrl;
      } else if (ehr.toUpperCase() === Constants.CERNER) {
        url = pragmaconnectCernerOrgsBaseUrl;
      } else {
        url = null;
      }
      if (!url) {
        throw new Error(`URL is not defined. Unable to retrieve organizations for EHR: ${ehr}`);
      }
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const orgList = await response.json();
      const updatedOrganization = orgList?.map((org: { name: any }) => ({
        ...org,
        label: org.name,
      }));
      const updatedOrganizationList = updatedOrganization.map((item: any) => {
        const { address, authorizationUrl, tokenUrl, ...rest } = item;
        return { label: address, value: item.label, address: address, authorizationUrl: authorizationUrl, tokenUrl: tokenUrl, ...rest };
      });
      setGetOrganizationList(updatedOrganizationList);
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleOnChange = (value: any, event: any) => {
    setSelectedOrganization({
      ...selectedOrganization,
      name: event.value,
      address: event.address,
      clientId: event.clientId,
      authorizationUrl: event.authorizationUrl,
      tokenUrl: event.tokenUrl,
    });
  };

  const generateAuthorizationUrl = async (selectedOrg: any, selectedHospital: DropDownValueModel | null) => {
    if (!checkOrganization) {
      showNotification({ color: 'red', message: 'Please select EHR.' });
      return;
    } else if (selectedOrganization.name === '') {
      showNotification({ color: 'red', message: 'Please select organization.' });
      return;
    }

    if (!selectedHospital || !selectedOrg?.keys) {
      throw new Error('Invalid inputs');
    }

    const address = selectedHospital.address;
    let hospitalUrl, tokenUrl;
    if (selectedOrg?.ehr?.toUpperCase() === Constants.EPIC) {
      hospitalUrl = address.substring(0, address.indexOf('/api')) + Urls.oauth2_authorize;
      tokenUrl = address;
    }
    if (selectedOrg?.ehr?.toUpperCase() === Constants.CERNER) {
      hospitalUrl = selectedHospital.authorizationUrl;
      tokenUrl = selectedHospital.tokenUrl;
    }
    const redirectURI = window.location.href.split('?')[0];
    const json = await decrypt(selectedOrg.keys);
    const config = JSON.parse(json);
    const clientId = selectedHospital?.clientId ? selectedHospital?.clientId : config?.clientId;
    localStorage.setItem('clientId', clientId);
    if (!clientId) {
      showNotification({
        color: 'red',
        message: `The ${hospitalUrl} is not currently configured for EHR integration.`,
      });
      return;
    }
    const scope = config[Constants.scope].replace(' ', '+');
    config.apiUrl = address;
    config.tokenUrl = tokenUrl;
    localStorage.setItem('config', JSON.stringify(config));
    localStorage.setItem('ehrPatient', 'true');

    let url = `${hospitalUrl}?client_id=${clientId}&response_type=code&redirect_uri=${redirectURI}&scope=${scope}`;

    if (selectedOrg?.ehr?.toUpperCase() == Constants.EPIC) {
      url = `${url}&state=epicauthmobile`;
    } else if (selectedOrg?.ehr?.toUpperCase() == Constants.CERNER) {
      url = `${url}&state=cernerauthmobile&aud=${address}`;
    }
    window.open(url, '_self');
  };

  const decrypt = async (encryptedText: string): Promise<string> => {
    try {
      const keyBytes = CryptoJS.enc.Utf8.parse(Urls.secretKey.padEnd(32, '\0')); // 32-byte key
      const [ivHex, ...encryptedTextParts] = encryptedText.split(':');
      const iv = CryptoJS.enc.Hex.parse(ivHex);
      const encrypted = CryptoJS.enc.Hex.parse(encryptedTextParts.join(':'));

      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: encrypted,
        iv: iv,
        key: keyBytes,
        algorithm: CryptoJS.algo.AES,
        //mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      const decrypted = CryptoJS.AES.decrypt(cipherParams, keyBytes, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (e) {
      throw new Error(`Decryption failed: ${e}`);
    }
  };

  return (
    <div className="tw-grid tw-justify-center">
      <h5 className="tw-text-[20px] tw-font-semibold tw-text-[#444444] tw-text-left tw-mt-[20px]">
        Login to EHR with SSO
      </h5>
      <p className="tw-text-[14px] tw-font-medium tw-text-[#888B92] tw-text-left">
        Select your EHR and organization to continue.
      </p>
      {isLoading ? (
        <div className="tw-grid tw-text-center tw-mt-[20px] tw-w-2/5 tw-block tw-m-auto">
          <Skeleton height={8} mb="xs" />
          <Skeleton height={8} mb="xs" />
          <Skeleton height={8} mb="xs" />
          <Skeleton height={8} mb="xs" />
        </div>
      ) : (
        <>
          <div className="tw-flex tw-text-center tw-justify-center tw-gap-5 tw-mt-[20px]">
            <Button
              type="button"
              className={ehrList?.find((item) => item?.ehr === Constants.EPIC) ? 'ssoButton' : 'disablebtn'}
              onClick={() => {
                const ehr = ehrList?.find((item) => item?.ehr === Constants.EPIC);
                if (ehr) handleClickBtn(ehr);
              }}
              style={{
                border: checkOrganization?.ehr?.toUpperCase() === Constants.EPIC ? '1px solid #9552E8' : '1px solid #BBBDBF',
              }}
            >
              <img
                src={EpicLogo}
                alt="Epic Logo"
                className={ehrList?.find((item) => item?.ehr === Constants.EPIC) ? 'logoImg' : 'disableImg'}
              />
            </Button>
            <Button
              type="button"
              className={ehrList?.find((item) => item?.ehr?.toUpperCase() === Constants.CERNER) ? 'ssoButton' : 'disablebtn'}
              onClick={() => {
                const ehr = ehrList?.find((item) => item?.ehr?.toUpperCase() === Constants.CERNER);
                if (ehr) handleClickBtn(ehr);
              }}
              style={{
                border: checkOrganization?.ehr?.toUpperCase() === Constants.CERNER ? '1px solid #9552E8' : '1px solid #BBBDBF',
              }}
            >
              <img
                src={CernerLogo}
                alt="Cerner Logo"
                className={ehrList?.find((item) => item?.ehr?.toUpperCase() === Constants.CERNER) ? 'logoImg' : 'disableImg'}
              />
            </Button>
          </div>
          <div className="tw-flex tw-text-center tw-justify-center tw-gap-5 tw-mt-[20px]">
            <Button
              type="button"
              className={ehrList?.find((item) => item?.ehr?.toUpperCase() === Constants.ATHENAHEALTH) ? 'ssoButton' : 'disablebtn'}
              //onClick={() => setLoginType('athena')}
              style={{
                border: checkOrganization?.ehr?.toUpperCase() === Constants.ATHENAHEALTH ? '1px solid #9552E8' : '1px solid #BBBDBF',
              }}
            >
              <img
                src={AthenaLogo}
                alt="Athena Logo"
                className={ehrList?.find((item) => item?.ehr?.toUpperCase() === Constants.ATHENAHEALTH) ? 'logoImg' : 'disableImg'}
              />
            </Button>
            <Button
              type="button"
              className={ehrList?.find((item) => item?.ehr?.toUpperCase() === Constants.NEXTGEN) ? 'ssoButton' : 'disablebtn'}
              // onClick={() => setLoginType('nextgen')}
              style={{
                border: checkOrganization?.ehr?.toUpperCase() === Constants.NEXTGEN ? '1px solid #9552E8' : '1px solid #BBBDBF',
              }}
            >
              <img src={NextGenLogo} alt="NextGen Logo" className={ehrList?.find((item) => item?.ehr?.toUpperCase() === Constants.NEXTGEN) ? 'logoImg' : 'disableImg'} />
            </Button>
          </div>
        </>
      )}
      <div className="tw-mt-[30px]">
        <Select
         key={ehr?.ehr || 'default'}
          label="Select Organization"
          placeholder="Select Organization"
          searchable
          data={getOrganizationList}
          onChange={(e, event) => handleOnChange(e, event)}
          mb={20}
          style={{ width: '100%', display: 'block', margin: 'auto' }}
        />
      </div>

      {loading ? (
        <Skeleton className="tw-h-[40px] tw-w-full tw-max-w-[400px] tw-mx-auto" />
      ) : (
        <div className="tw-text-center tw-pb-[20px]">
          <Button
            type="button"
            className="tw-w-full sign-btn"
            onClick={() => generateAuthorizationUrl(ehr, selectedOrganization)}
            style={{ backgroundColor: brandDetails?.buttonColor }}
          >
            Proceed To Login
          </Button>
        </div>
      )}

      <div className="tw-text-center">
        <Divider my="xs" label="OR" labelPosition="center" />

        {loading ? (
          <Skeleton className="tw-h-[40px] tw-w-full tw-max-w-[400px] tw-mx-auto" />
        ) : (
          <div className="tw-text-center">
            <Button
              type="button"
              className="tw-w-full sm:tw-w-[100%] sign-btn"
              onClick={() => navigate('/singin')}
              style={{ backgroundColor: `${brandDetails?.buttonColor || '#9552E8'}` }}
            >
              Log in with Email
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginWithSSO;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { getSlots } from '../utils/CustomAPI';
import { Text } from '@mantine/core';
import { useAppContext } from '../AppProvider';
import { useMedplum } from '@medplum/react-hooks';

interface SlotsProps {
  onSelectSlot: (slot: any) => void;
  serviceId?: string;
  startDate: string;
  formStartDate?: string;
  slotError: boolean;
  currentSlotId?: string;
}

interface Slot {
  id: string;
  time: string;
  startTime: string;
  endTime: string;
}

const Slots: React.FC<SlotsProps> = (props: SlotsProps) => {
  const [activeSlot, setActiveSlot] = useState<string>('');
  const medplum = useMedplum();
  const [slots, setSlots] = useState<Slot[]>([]);
  const [isSlotsEmpty, setIsSlotsEmpty] = useState<boolean>(false);
  const { scheduleId } = useAppContext();

  const onClickSlot = (slot: Slot) => {
    setActiveSlot(slot?.id);
    props.onSelectSlot(slot);
  };

  useEffect(() => {
    if (props.startDate) {
      getSlotsList();
    }
  }, [props.startDate]);

  const formatDate = (date: string | number | Date, p0: string) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getSlotsList = () => {
    const payload = {
      scheduleId: scheduleId,
      startDate: formatDate(new Date(props.startDate), 'YYYY-MM-DD'),
      serviceId: props.serviceId,
    };
    getSlots(medplum, payload)
      .then((res) => {
        if (res.data.entry.length === 0) {
          setIsSlotsEmpty(true);
          setSlots([]);
        } else {
          setIsSlotsEmpty(false);
          setSlots(
            res.data.entry.map((slot: any) => {
              return {
                id: slot.resource.id,
                time: new Date(slot.resource.start).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
                startTime: slot.resource.start,
                endTime: slot.resource.end,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.error('Error fetching slots:', err);
        setIsSlotsEmpty(true);
        setSlots([]);
      });
  };
  return (
    <>
      {slots.length > 0 && (
        <>
          <div className="tw-flex tw-items-center tw-gap-4">
            {/* Label Section */}
            <div className="tw-flex tw-items-center tw-min-w-[150px]">
              <label className="tw-text-[#000] tw-font-medium">Available Slots</label>
              <Text component="span" className="tw-text-red-500">
                {' '}
                *
              </Text>
            </div>

            {/* Slots Section */}
            <div className="slots_container tw-flex tw-flex-wrap tw-gap-2" style={{ marginLeft: '3.25rem' }}>
              {slots.map((slot) => {
                const isActiveSlot = activeSlot === slot.id;
                const slotStyle = {
                  backgroundColor: isActiveSlot ? '#4DB5BA' : 'white',
                  color: isActiveSlot ? 'white' : '#333',
                  cursor: 'pointer',
                };

                return (
                  <div
                    key={`${slot.id}-${slot.time}`}
                    onClick={() => onClickSlot(slot)}
                    className="slots_btn tw-w-[100px] tw-text-center tw-py-2 tw-rounded"
                    style={slotStyle}
                  >
                    <span>{slot.time}</span>
                  </div>
                );
              })}

              {props?.slotError && <span className="tw-font-medium tw-text-[red] tw-ml-2">Please select slot</span>}
            </div>
          </div>
        </>
      )}
      {isSlotsEmpty && (
        <>
          <div className="flex items-center">
            <label className="tw-text-[#000] tw-font-medium">Available Slots</label>
            <Text component="span" className="tw-text-red-500">
              {' '}
              *
            </Text>
          </div>

          <div className="tw-text-center tw-font-[400] tw-mt-10 tw-mb-10 tw-text-[14px]">No slots available.</div>
        </>
      )}
    </>
  );
};

export default Slots;

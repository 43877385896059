/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AreaChart, Area, Tooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { DataPoint } from '../../interfaces/DataPoint';
import { Skeleton, Modal } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { useEffect, useState } from 'react';
import { getWeight } from '../../utils/CustomAPI';
import { IconArrowDown, IconArrowUp, IconExternalLink } from '@tabler/icons-react';
import { formatDate } from '../../utils/util';

const Weight = () => {
  const medplum = useMedplum();
  const [weightData, setWeightData] = useState<DataPoint[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [weightAvg, setWeightAvg] = useState<number>(0);
  const [percentageChange, setPercentageChange] = useState<string>('0.00');
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to remove duplicate entries
  const removeDuplicates = (data: DataPoint[]) => {
    const uniqueData = data.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.date === value.date && t.value === value.value
      ))
    );
    return uniqueData;
  };

  // Get weight data
  useEffect(() => {
    setIsLoading(true);
    const previousMonth = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString();
    getWeight(medplum, medplum.getProfile()?.id || '', previousMonth).then((res) => {
      setIsLoading(false);
      const filteredData = res?.data.entry.filter((entry: any) => {
        const code = entry.resource.code.coding[0].display;
        return code === 'Weight' || code === 'Body weight';
      });

      const sortedData = filteredData
        .map((entry: any) => ({
          value: entry.resource.valueQuantity?.value,
          unit: entry.resource.valueQuantity?.unit.toString(),
          date: entry.resource.effectiveDateTime,
        }))
        .filter((entry: DataPoint) => new Date(entry.date) <= new Date()) // Filter out future dates
        .sort((a: DataPoint, b: DataPoint) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .map((entry: DataPoint) => ({
          ...entry,
          date: formatDate(entry.date),
        }));
      const uniqueData = removeDuplicates(sortedData); // Remove duplicate entries
      setWeightData(uniqueData);
      const weights = filteredData.slice(1).map((entry: any) => entry.resource.valueQuantity?.value).filter((w: number) => w > 0); // Remove 0 values
        if (weights.length > 1) {
          const initialWeight = weights[0]; // First recorded weight
          const latestWeight = weights[weights.length - 1]; // Most recent weight

          // Calculate average weight
          const sum = weights.reduce((a: any, b: any) => a + b, 0);
          const avg = sum / weights.length;
          console.log(avg)
          setWeightAvg(parseFloat(avg.toFixed(2)));

          // Calculate percentage change based on initial weight
          const percentageChange = initialWeight
            ? ((latestWeight - initialWeight) / initialWeight) * 100
            : 0; // Avoid division by zero

          setPercentageChange(percentageChange.toFixed(2));
        }
      
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  return (
    <div className="tw-mt-6 xs:tw-mt-2 tw-px-5 tw-py-4 tw-block tw-w-[50%] tw-h-fit tw-bg-white tw-border border-[#F2F2F2] tw-rounded-[12px] featured-card">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
        <h5 className="tw-text-[16px] tw-font-semibold tw-tracking-tight tw-text-[#101828] tw-pb-0">
          Weight
        </h5>
        {weightData.length > 0 && (
          <IconExternalLink
            size={20}
            className="tw-cursor-pointer tw-text-[#667085]"
            title="Click to track your weight changes."
            onClick={() => setIsModalOpen(true)}
          />
        )}
      </div>
      {!isLoading ? (
        <div className="tw-flex tw-justify-between tw-mt-5">
          <div className='tw-grid tw-w-[50%]'>
            <span className="tw-font-semibold tw-text-[#101828] tw-text-[22px] tw-mb-3">
              {weightData?.length > 0 ? `${weightData[weightData.length - 1]?.value} kg` : '0 kg'}
            </span>
            <span className='tw-text-[#067647] tw-flex tw-items-center tw-font-medium' style={{
              color: weightAvg > weightData?.[0]?.value || weightAvg === 0.00 ? '#17B26A' : '#FF5C58',
            }}>
              {weightAvg > weightData?.[0]?.value || weightAvg === 0.00 ? <IconArrowUp size={15} color='#17B26A'/> : <IconArrowDown size={15} color='#FF5C58'/>}
              {percentageChange}%
              <span className='tw-ml-1 tw-text-[#475467] tw-font-medium'>vs last month</span>
            </span>
          </div>
          <div className='tw-w-[50%]'>
            <ResponsiveContainer>
              <AreaChart data={weightData} barSize={5}>
                <defs>
                  <linearGradient id="colorUv4" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={weightAvg > weightData?.[0]?.value || weightAvg === 0.00 ? "#17B26A" : '#FF5C58'} stopOpacity={0.8}/>
                    <stop offset="95%" stopColor={weightAvg > weightData?.[0]?.value || weightAvg === 0.00 ? "#17B26A" : '#FF5C58'} stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <XAxis dataKey="date" tickLine={false} display='none'/>
                <Tooltip cursor={false} />
                <Area
                  dataKey="value"
                  fill="url(#colorUv4)"
                  stroke={weightAvg > weightData?.[0]?.value || weightAvg === 0.00 ? "#17B26A" : '#FF5C58'}
                  strokeWidth={2}
                  name='Weight'
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className='tw-grid tw-text-center tw-mt-[20px] tw-block tw-m-auto'>
          <Skeleton height={8} mb="xs" />
          <Skeleton height={8} mb="xs" />
          <Skeleton height={8} mb="xs" />
        </div>
      )}
      {/* Modal for Enlarged Chart */}
      <Modal opened={isModalOpen} onClose={() => setIsModalOpen(false)} title={<span style={{ fontSize: '16px', fontWeight: 600 }}>Weight</span>} size="lg" centered>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={weightData} margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
            <XAxis
              dataKey="date"
              tickFormatter={(tick) => {
                const date = new Date(tick.split('/').reverse().join('-'));
                return `${date.getDate()}\n${date.toLocaleDateString('en-US', { month: 'short' })}`;
              }}
              interval={0}
              angle={-25}
              dy={10}
              tick={{ fontSize: 10, textAnchor: "middle" }}
            />
            <YAxis tick={{ fontSize: 10 }} />
            <Tooltip cursor={false} />
            <Area
              dataKey="value"
              fill="url(#colorUv4)"
              stroke={weightAvg > weightData?.[0]?.value || weightAvg === 0.00 ? "#17B26A" : '#FF5C58'}
              strokeWidth={2}
              name='Weight'
            />
          </AreaChart>
        </ResponsiveContainer>
      </Modal>
    </div>
  );
};

export default Weight;
